// import * as THREE from 'three'
import Experience from './Experience.js'
import Japan from './Japan.js'
import Background from './Background.js'
export default class World {
  constructor (_options) {
    this.experience = new Experience()
    this.config = this.experience.config
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.resources.on('groupEnd', (_group) => {
      if (_group.name === 'base') {
        this.setJapan()
        this.setBackground()
      }
    })
  }

  setJapan () {
    this.japan = new Japan()
  }

  setBackground () {
    this.background = new Background()
  }

  resize () {
    // this.hero.resize()
    if (this.japan) {
      this.japan.resize()
    }
    if (this.background) {
      this.background.resize()
    }
  }

  update (elapsed) {
    if (this.japan) {
      this.japan.update()
    }
    if (this.background) {
      this.background.update(elapsed)
    }
  }

  rerun () {
    this.setJapan()
    this.setBackground()
  }

  destroy () {
    this.japan.destroy()
    this.background.destroy()
  }
}
