var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import barba from '@barba/core';
import barbaRouter from '@barba/router';
import barbaPrefetch from '@barba/prefetch';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Preloader from './Preloader';
import App from './script';
gsap.registerPlugin(ScrollTrigger);
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default class Transition {
    constructor() {
        this.app = new App();
        this.myRoutes = [
            { name: 'home', path: '/' },
            { name: 'project-detail1', path: '/project-detail1.html' },
            { name: 'project-detail2', path: '/project-detail2.html' },
            { name: 'project-detail3', path: '/project-detail3.html' },
            { name: 'project-detail4', path: '/project-detail4.html' },
            { name: 'project-detail5', path: '/project-detail5.html' },
            { name: 'project-detail6', path: '/project-detail6.html' },
        ];
        barba.use(barbaRouter, {
            routes: this.myRoutes
        });
        barba.use(barbaPrefetch);
        this.init();
    }
    init() {
        const cover = document.querySelector('.transition-cover');
        const app = this.app;
        barba.hooks.beforeEnter((data) => {
            var _a;
            const triggers = ScrollTrigger.getAll();
            triggers.forEach((trigger) => {
                trigger.kill();
            });
            (_a = data.current.container) === null || _a === void 0 ? void 0 : _a.remove();
        });
        barba.init({
            preventRunning: true,
            prevent: ({ el }) => el.classList && el.classList.contains('prevent-barba'),
            transitions: [
                {
                    name: 'destroy-home-instance',
                    from: {
                        route: ['home']
                    },
                    beforeLeave() {
                        app.destroyExperience();
                    },
                    leave() {
                        return __awaiter(this, void 0, void 0, function* () {
                            if (this.async) {
                                yield gsap.to(cover, {
                                    duration: 1,
                                    opacity: 1,
                                    onStart: () => {
                                        cover.style.pointerEvents = 'auto';
                                        gsap.to(window, {
                                            openingProgress: 1,
                                            duration: 0.1,
                                            ease: 'power1.inOut'
                                        });
                                    }
                                });
                            }
                        });
                    },
                    enter(data) {
                        app.initExperience();
                        app.toHorizontalScroll();
                        if (window.location.pathname === '/') {
                            return;
                        }
                        else {
                            app.setDetailStartAnimation();
                        }
                        gsap.to(cover, {
                            duration: 1,
                            opacity: 0,
                            delay: 0.5,
                            onComplete: () => {
                                cover.style.pointerEvents = 'none';
                            }
                        });
                    }
                },
                {
                    name: 'general-transition',
                    once() {
                        if (window.location.pathname === '/') {
                            const preloader = new Preloader();
                        }
                        else {
                            app.setDetailStartAnimation();
                        }
                    },
                    beforeLeave(data) {
                        app.destroyExperience();
                    },
                    leave(data) {
                        return __awaiter(this, void 0, void 0, function* () {
                            if (this.async) {
                                yield gsap.to(cover, {
                                    duration: 1,
                                    opacity: 1,
                                    onStart: () => {
                                        cover.style.pointerEvents = 'auto';
                                    }
                                });
                            }
                        });
                    },
                    enter(data) {
                        app.initExperience();
                        if (window.location.pathname === '/') {
                            return;
                        }
                        else {
                            app.toHorizontalScroll();
                            app.setDetailStartAnimation();
                        }
                        gsap.to(cover, {
                            duration: 1,
                            opacity: 0,
                            delay: 0.5,
                            onComplete: () => {
                                cover.style.pointerEvents = 'none';
                            }
                        });
                    }
                },
                {
                    name: 'home-script',
                    to: {
                        route: ['home']
                    },
                    beforeLeave(data) {
                        app.destroyExperience();
                    },
                    leave(data) {
                        return __awaiter(this, void 0, void 0, function* () {
                            if (this.async) {
                                yield gsap.to(cover, {
                                    duration: 1,
                                    opacity: 1,
                                    onStart: () => {
                                        cover.style.pointerEvents = 'auto';
                                    }
                                });
                            }
                        });
                    },
                    beforeEnter(data) {
                        return __awaiter(this, void 0, void 0, function* () {
                            app.initExperience();
                        });
                    },
                    enter() {
                        return __awaiter(this, void 0, void 0, function* () {
                            app.toVerticalScroll();
                            gsap.to(cover, {
                                duration: 1,
                                opacity: 0,
                                delay: 0.5,
                                onComplete: () => {
                                    cover.style.pointerEvents = 'none';
                                }
                            });
                            const heroTitleTop = document.querySelector('.js-hero__title-1>.line>p');
                            const heroTitleBottom = document.querySelector('.js-hero__title-2>.line>p');
                            const heroNameTop = document.querySelector('.js-hero__nameTop>.line>p');
                            const heroNameBottom = document.querySelector('.js-hero__nameBottom>.line>p');
                            const heroAge = document.querySelector('.js-hero__age>.line>p');
                            const headerLogo = document.querySelector('.js-header__logo>p');
                            const headerNavs = Array.from(document.querySelectorAll('.js-page-nav__item>.line'));
                            const headerMainNav = document.querySelector('.js-header__main-nav');
                            const durationRatio = 0.7;
                            const contentDelay = 0.5;
                            const contentTl = gsap.timeline();
                            contentTl.to(window, {
                                openingProgress: 0,
                                duration: 3,
                                ease: 'power1.inOut'
                            })
                                .from(heroTitleTop, {
                                duration: 2 * durationRatio,
                                delay: 0.5 * durationRatio,
                                opacity: 0,
                                y: '100%',
                                ease: 'power3.out'
                            }, contentDelay)
                                .from(heroTitleBottom, {
                                duration: 2 * durationRatio,
                                opacity: 0,
                                delay: 0.7 * durationRatio,
                                y: '100%',
                                ease: 'power3.out'
                            }, contentDelay)
                                .from(heroNameBottom, {
                                duration: 1.8 * durationRatio,
                                opacity: 0,
                                delay: 0.9 * durationRatio,
                                y: '100%',
                                ease: 'power3.out'
                            }, contentDelay)
                                .from(heroNameTop, {
                                duration: 1.8 * durationRatio,
                                opacity: 0,
                                delay: 1.1 * durationRatio,
                                y: '100%',
                                ease: 'power3.out'
                            }, contentDelay)
                                .from(heroAge, {
                                duration: 2.2 * durationRatio,
                                opacity: 0,
                                delay: 0.7 * durationRatio,
                                y: '100%',
                                ease: 'power3.out'
                            }, contentDelay);
                            contentTl.from(headerLogo, {
                                duration: 1.5 * durationRatio,
                                opacity: 0,
                                delay: 1.8 * durationRatio,
                                y: '100%',
                                ease: 'power3.out'
                            }, contentDelay)
                                .from(headerNavs[0], {
                                duration: 1 * durationRatio,
                                opacity: 0,
                                delay: 2.0 * durationRatio,
                                y: '200%',
                                ease: 'power2.out'
                            }, contentDelay)
                                .from(headerNavs[1], {
                                duration: 1 * durationRatio,
                                opacity: 0,
                                delay: 2.1 * durationRatio,
                                y: '200%',
                                ease: 'power2.out'
                            }, contentDelay)
                                .from(headerNavs[2], {
                                duration: 1 * durationRatio,
                                opacity: 0,
                                delay: 2.2 * durationRatio,
                                y: '200%',
                                ease: 'power2.out'
                            }, contentDelay)
                                .from(headerNavs[3], {
                                duration: 1 * durationRatio,
                                opacity: 0,
                                delay: 2.3 * durationRatio,
                                y: '200%',
                                ease: 'power2.out'
                            }, contentDelay)
                                .from(headerNavs[4], {
                                duration: 1 * durationRatio,
                                opacity: 0,
                                delay: 2.4 * durationRatio,
                                y: '200%',
                                ease: 'power2.out'
                            }, contentDelay)
                                .from(headerMainNav, {
                                duration: 1 * durationRatio,
                                opacity: 0,
                                delay: 2.6 * durationRatio,
                                y: '200%',
                                ease: 'power2.out'
                            }, contentDelay);
                        });
                    }
                },
            ]
        });
    }
}
