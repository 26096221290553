import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ImageScroll {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.pageWrapper = document.querySelector('[data-scroll]')
    this.contentWrapper = document.querySelector('.js-inspirations-scroll')
    this.title = document.querySelector('.js-inspirations-title')
    this.imagesWrapper = document.querySelector('.js-inspirations-images')
    this.images = document.querySelectorAll('.js-inspirations-images > *')

    this.contentWrapperYPos = this.contentWrapper.getBoundingClientRect().top
    this.imagesWrapperHeight = this.imagesWrapper.offsetHeight

    this.setScrollTrigger()
  }

  setScrollTrigger () {
    this.tl = gsap.timeline({
      scrollTrigger: {
        scroller: this.pageWrapper,
        scrub: 1,
        trigger: this.contentWrapper,
        pin: true,
        start: 'top top',
        markers: false,
        end: () => `+=${this.imagesWrapperHeight}`
      }
    })

    if (this.mediaQuery.matches) {
      this.tl.to(this.imagesWrapper, {
        y: -this.imagesWrapperHeight / 1.9,
        ease: 'none'
      }, 0)
        .to(this.title, {
          y: '-170%',
          ease: 'none'
        }, 0)
        .to(this.images[0], {
          y: '-20%'
        }, 0)
        .to(this.images[1], {
          y: '-80%'
        }, 0)
        .to(this.images[2], {
          y: '-120%'
        }, 0)
        .to(this.images[3], {
          y: '-60%'
        }, 0)
        .to(this.images[5], {
          y: '-120%'
        }, 0)
        .to(this.images[6], {
          y: '-20%'
        }, 0)
    } else {
      this.tl.to(this.imagesWrapper, {
        y: -this.imagesWrapperHeight / 1.3,
        ease: 'none'
      }, 0)
        .to(this.title, {
          y: '-170%',
          ease: 'none'
        }, 0)
        .to(this.images[0], {
          y: '-20%'
        }, 0)
        .to(this.images[1], {
          y: '-80%'
        }, 0)
        .to(this.images[2], {
          y: '-120%'
        }, 0)
        .to(this.images[3], {
          y: '-60%'
        }, 0)
        .to(this.images[5], {
          y: '-120%'
        }, 0)
        .to(this.images[6], {
          y: '-20%'
        }, 0)
    }
  }

  destroy () {
    this.tl.kill()
    this.tl = null
  }
}
