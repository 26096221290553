import { gsap } from 'gsap';
import { Tween1 } from './Tween';
import Pointer from './Pointer';
export default class Cursor {
    constructor(el) {
        if (Cursor.instance)
            return Cursor.instance;
        this.pointer = new Pointer();
        this.DOM = { el: el };
        this.bounds = this.DOM.el.getBoundingClientRect();
        this.DOM.el.style.opacity = '0';
        this.DOM.el.style.transform = 'translate3d(0, 0, 0)';
        this.innerCursor = this.DOM.el.querySelector('.pointer__inner');
        this.cursorTl = gsap.timeline();
        gsap.set(this.innerCursor, { scale: 0, rotateZ: 45 });
        this.cursorConfig = {
            x: { previous: 0, current: 0, amt: 0.2 },
            y: { previous: 0, current: 0, amt: 0.2 },
            scale: { previous: 0, current: 0, amt: 20 },
        };
        window.addEventListener('mousemove', this.onMouseMoveEvent.bind(this));
        Cursor.instance = this;
    }
    onMouseMoveEvent() {
        gsap.to(this.DOM.el, { duration: 0.5, ease: 'Power3.easeInOut', opacity: 1 });
        window.removeEventListener('mousemove', this.onMouseMoveEvent);
    }
    render() {
        this.cursorConfig.x.current = gsap.utils.interpolate(this.cursorConfig.x.current, this.pointer.x - this.bounds.width / 2, 0.1);
        this.cursorConfig.y.current = gsap.utils.interpolate(this.cursorConfig.y.current, this.pointer.y - this.bounds.height / 2, 0.1);
        for (const key in this.cursorConfig) {
            this.tween = new Tween1(this.cursorConfig[key].previous, 0.01);
            this.tween.update(this.cursorConfig[key].current, this.cursorConfig[key].amt);
            this.cursorConfig[key].previous = this.tween.x;
        }
        this.DOM.el.style.transform = `translateX(${this.cursorConfig.x.current}px) translateY(${this.cursorConfig.y.current}px) scale(${this.cursorConfig.scale.previous})`;
    }
    update() {
        this.render();
    }
    enter() {
        this.cursorConfig['scale'].current = 1;
        this.cursorTl.to(this.innerCursor, { duration: 0.4, delay: 0.2, ease: 'power3.out', scale: 1 }, 0);
        this.cursorTl.to(this.innerCursor, { duration: 0.3, ease: 'none', rotateZ: 0 }, 0.2);
    }
    leave() {
        this.cursorConfig['scale'].current = 0;
        this.cursorTl.progress(0);
    }
    destroy() {
        window.removeEventListener('mousemove', this.onMouseMoveEvent);
        Cursor.instance = null;
    }
}
