import * as THREE from 'three'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import CustomEase from 'gsap/CustomEase'
import Experience from './Experience.js'
import Scroll from '../scroll'
import Pointer from '../Pointer'
import SplitText from '../SplitText'
import vertexShader from './Shaders/horizontalImageWaterFlow/vertexShader.glsl'
import fragmentShader from './Shaders/horizontalImageWaterFlow/fragmentShader.glsl'
import globalMenuBGVertexShader from './Shaders/globalMenu/vertexShader.glsl'
import globalMenuBGFragmentShader from './Shaders/globalMenu/fragmentShader.glsl'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(CustomEase)

window.isMenuOpen = false
export default class HorizontalScroll {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 68.75rem)')
    this.timelines = []
    this.experience = new Experience()
    this.scroll = new Scroll()
    this.pointer = new Pointer()
    this.resources = this.experience.resources
    this.config = this.experience.config
    this.scene = this.experience.scene
    this.currentScroll = this.scroll.value.current
    this.horizontalScrollProgress = 0
    this.horizontalScrollProgressWidth = 0

    this.mobileImageScale = 1

    this.mouse = new THREE.Vector2()
    this.setSettings()
    this.init()
    // イメージのホバーエフェクト
    this.textureLoaded().then(() => {
      this.isLoaded = true
      this.setMesh()
      this.addEventListeners()
      this.setMenuBG()
    })
  }

  setSettings () {
    if (this.mediaQuery.matches) {
      this.mobileImageScale = 0.7
    }
    this.pageWrapper = document.querySelector('div[data-scroll]')
    this.homeWrapper = document.querySelector('.is-home')
    this.contents = gsap.utils.toArray('.js-list-item')
    this.menuContents = gsap.utils.toArray('.js-menulist-item')
    this.allContents = this.contents.concat(this.menuContents)
    this.globalmenuWrapper = document.querySelector('.js-global-menu-content')
    this.firstViewWrapper = document.querySelector('.js-first-view-wrapper')
    this.firstViewContentWrapper = document.querySelector('.js-archives-titleContents')
    this.titleWrapper = document.querySelector('.js-first-view-wrapper')
    this.contentWrapper = document.querySelector('.js-horizontal-scroll')
    this.contentmenuWrapper = document.querySelector('.js-horizontal-menu')
    this.lines = gsap.utils.toArray('.js-fadeIn-line')
    this.titles = gsap.utils.toArray('.js-horizontal-fadein-title')
    this.firstViewWidth = this.firstViewWrapper.offsetWidth
    this.wrapperWidth = this.contentWrapper.offsetWidth
    this.wrapperYPos = this.scroll.value.current + this.contentWrapper.getBoundingClientRect().top
    this.horizontalScrollLength = this.wrapperWidth - window.innerWidth
    // 画像の表示完了トリガー
    this.isFading = false
    this.currentMenuIndex = null

    this.planeWaveTl = gsap.timeline()
  }

  init () {
    this.setScrollTrigger()
    this.lineFadeIn()
  }

  _handleScroll (event) {
    event.preventDefault()
  }

  // WebGLとのスクロール連動
  setScrollTrigger () {
    this.archivesTitleWrapper = document.querySelector('.js-archives-title')
    this.archivesTitleTexts = Array.from(this.archivesTitleWrapper.querySelectorAll('.line>p'))
    this.archivesDescriptionWrapper = document.querySelector('.js-archives-descriptions')
    this.archivesDescriptionTexts = Array.from(this.archivesDescriptionWrapper.querySelectorAll('.line'))
    this.archivesTitleWidth = this.archivesTitleWrapper.offsetWidth
    this.delay = 0.8
    this.duration = 0.4
    this.fadeInDuration = 0.6
    this.titleEase = 'power3.out'
    this.fadeInEase = 'none'

    // ARCHIVESアニメーション
    // タイトルテキストアニメーション
    this.descriptionDuration = 0.5
    this.descriptionFadeInDuration = 0.8
    this.archivesTitleEase = 'power3.out'

    this.horizontalScrollStartTl = gsap.timeline({
      scrollTrigger: {
        scroller: this.pageWrapper,
        trigger: this.contentWrapper,
        start: 'top top+=0.1%',
        toggleActions: 'play none reverse none'
      }
    })

    this.archivesTitleTexts.forEach(text => {
      gsap.set(text, {
        opacity: 0,
        y: '100%'
      })
    })

    this.archivesDescriptionTexts.forEach(text => {
      gsap.set(text, {
        opacity: 0,
        y: '100%'
      })
    })

    this.horizontalScrollStartTl.to(this.archivesTitleTexts[0], {
      y: '0%',
      delay: this.delay,
      duration: this.duration + 0.4,
      ease: this.archivesTitleEase
    }, 0)
      .to(this.archivesTitleTexts[0], {
        duration: this.fadeInDuration + 0.4,
        delay: this.delay,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesTitleTexts[1], {
        y: '0%',
        duration: this.duration + 0.4,
        delay: this.delay + 0.2,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesTitleTexts[1], {
        duration: this.fadeInDuration + 0.4,
        delay: this.delay + 0.2,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesDescriptionTexts[0], {
        y: '0%',
        duration: this.descriptionDuration,
        delay: this.delay + 0.4,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesDescriptionTexts[0], {
        duration: this.descriptionFadeInDuration,
        delay: this.delay + 0.4,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesDescriptionTexts[1], {
        y: '0%',
        duration: this.descriptionDuration,
        delay: this.delay + 0.5,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesDescriptionTexts[1], {
        duration: this.descriptionFadeInDuration,
        delay: this.delay + 0.5,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesDescriptionTexts[2], {
        y: '0%',
        duration: this.descriptionDuration,
        delay: this.delay + 0.6,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesDescriptionTexts[2], {
        duration: this.descriptionFadeInDuration,
        delay: this.delay + 0.6,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesDescriptionTexts[3], {
        y: '0%',
        duration: this.descriptionDuration,
        delay: this.delay + 0.7,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesDescriptionTexts[3], {
        duration: this.descriptionFadeInDuration,
        delay: this.delay + 0.7,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesDescriptionTexts[4], {
        y: '0%',
        duration: this.descriptionDuration,
        delay: this.delay + 0.8,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesDescriptionTexts[4], {
        duration: this.descriptionFadeInDuration,
        delay: this.delay + 0.8,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)
      .to(this.archivesDescriptionTexts[5], {
        y: '0%',
        duration: this.descriptionDuration,
        delay: this.delay + 0.9,
        ease: this.archivesTitleEase
      }, 0)
      .to(this.archivesDescriptionTexts[5], {
        duration: this.descriptionFadeInDuration,
        delay: this.delay + 0.9,
        opacity: 1,
        ease: this.fadeInEase
      }, 0)

    this.timelines.push(this.horizontalScrollStartTl)

    // 導入フェードイン（onEnterのみ）
    if (!this.horizontalScrollSlideTl) {
      this.onFadeIn = false
      this.horizontalScrollSlideTl = gsap.timeline({
        scrollTrigger: {
          scroller: this.pageWrapper,
          trigger: this.contentWrapper,
          start: 'top top',
          end: 'bottom+=30% bottom',
          toggleActions: 'play none none reset',
          onEnter: (self) => {
            this._horizontalFadeInAnimation()
          }
        }
      })
    }

    // 横スクロールUI
    if (!this.horizontalScrollTl) {
      const scrubStrength = this.mediaQuery.matches ? 1 : 0.5
      let scrollHoverElement = null
      this.horizontalScrollTl = gsap.timeline({
        scrollTrigger: {
          scroller: this.pageWrapper,
          scrub: scrubStrength,
          trigger: this.contentWrapper,
          pin: true,
          start: 'top top',
          end: () => `+=${this.wrapperWidth}`,
          markers: false,
          preventOverlaps: true,
          onUpdate: (self) => {
            this.horizontalScrollProgressWidth = self.progress * this.wrapperWidth
            if (this.mediaQuery.matches) {
              scrollHoverElement = document.elementsFromPoint(this.config.width / 2, this.config.height / 2).find(element => element.classList.contains('js-list-item'))
            } else {
              scrollHoverElement = document.elementsFromPoint(this.pointer.x, this.pointer.y).find(element => element.classList.contains('js-list-item'))
            }
            if (scrollHoverElement) {
              this._onMouseOver(scrollHoverElement.dataset.archiveIndex)
            } else {
              this._onMouseLeave()
            }
          },
          onLeave: () => {
            window.canvasOnScroll = true
            if (!window.onAnchorScrolling && this.globalMenuBG) {
              gsap.to(this.globalMenuBG.material.uniforms.uAlpha, {
                value: 0,
                duration: 0.5,
                ease: 'expo.out'
              })
            }
          },
          onEnterBack: () => {
            if (!window.onAnchorScrolling) {
              window.canvasOnScroll = false
            }
          },
          onLeaveBack: () => {
            if (!window.onAnchorScrolling && this.globalMenuBG) {
              gsap.to(this.globalMenuBG.material.uniforms.uAlpha, {
                value: 0,
                duration: 0.5,
                ease: 'expo.out'
              })
            }
            window.canvasOnScroll = true
          }
        }
      })
      this.horizontalScrollTl
        .to(this.contentWrapper, {
          x: () => -this.horizontalScrollLength,
          ease: 'none'
        })
    }
  }

  _horizontalFadeInAnimation () {
    // スクロール停止
    if (!window.onAnchorScrolling && !this.onFadeIn) {
      this.onFadeIn = true
      this.scroll.value.isActive = false
      setTimeout(() => {
        window.canvasOnScroll = false
      }, 200)
      document.removeEventListener('wheel', this._handleScroll, { passive: false })
      document.removeEventListener('touchmove', this._handleScroll, { passive: false })
      this.scroll.value.target = this.scroll.value.current

      gsap.to(this.scroll.value, {
        target: (index, target, targets) => {
          return this.scroll.value.current + (this.firstViewWidth / 1.6)
        },
        duration: 3.2,
        delay: this.delay + this.fadeInDuration,
        ease: 'expo.inOut',
        onComplete: () => {
          this.onFadeIn = false
          this.scroll.value.isActive = true
          document.addEventListener('wheel', this._handleScroll, { passive: false })
          document.addEventListener('touchmove', this._handleScroll, { passive: false })
        }
      })
    }
  }

  lineFadeIn () {
    const lineDuration = 2.5
    const firstLine = document.querySelector('.js-fadeIn-line-first')

    gsap.set(firstLine, {
      y: '-100%'
    })

    this.lines.forEach((line, index) => {
      gsap.set(line, {
        y: '-100%'
      })
    })

    gsap.to(firstLine, {
      scrollTrigger: {
        trigger: this.contents[0],
        containerAnimation: this.horizontalScrollTl,
        start: 'left center+=30%',
        toggleActions: 'play none none none',
        markers: false
      },
      y: '0%',
      duration: lineDuration,
      ease: 'power4.out'
    })

    this.itemContentsStore = this.contents.map(content => {
      const line = content.querySelector('.js-fadeIn-line')
      const titleTexts = Array.from(content.querySelectorAll('.js-horizontal-fadein-title>.line>p'))
      const splitText1 = new SplitText(titleTexts[0], false, true, content)
      const age = content.querySelector('.js-archives__itemAge>.line>p')
      const splitTextAge = new SplitText(age, false, content)

      if (titleTexts[1]) {
        const splitText2 = new SplitText(titleTexts[1], false, true, content)
        return {
          content,
          line,
          splitText1,
          splitText2,
          splitTextAge
        }
      } else {
        return {
          content,
          line,
          splitText1,
          splitTextAge
        }
      }
    })

    this.itemContentsStore.forEach((item, index) => {
      item.splitText1.setAnimateVerticalTextEnglish(0.7)
      if (item.splitText2) {
        item.splitText2.setAnimateVerticalTextEnglish(0.7)
      }
      item.splitTextAge.setAnimateVerticalTextEnglish(0.7)

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: () => item.content,
          containerAnimation: this.horizontalScrollTl,
          start: () => 'left center+=30%',
          end: () => 'left left',
          toggleActions: 'play none none none',
          markers: false,
          onEnter: () => {
            item.splitText1.animateVerticalTextEnglish(0.6, 0.05, 0)
            if (item.splitText2) {
              item.splitText2.animateVerticalTextEnglish(0.6, 0.05, 1)
              item.splitTextAge.animateVerticalTextEnglish(0.6, 0.05, 2)
            } else {
              item.splitTextAge.animateVerticalTextEnglish(0.6, 0.05, 1)
            }
          }
        }
      })
      tl.to(item.line, {
        y: '0%',
        duration: lineDuration,
        ease: 'power4.out'
      })
      this.timelines.push(tl)
    })
  }

  setMenuBG () {
    let initialAlpha = 0
    if (window.isMenuOpen) {
      initialAlpha = 1
    } else {
      initialAlpha = 0
    }
    // global-menuBG
    this.geometryGlobalMenuBG = new THREE.PlaneGeometry(this.config.width * 2, this.config.height * 2, 30, 30)
    this.materialGlobalMenuBG = new THREE.ShaderMaterial(
      {
        uniforms: {
          uResolution: { value: new THREE.Vector2(this.config.width, this.config.height) },
          uAlpha: { value: initialAlpha }
        },
        vertexShader: globalMenuBGVertexShader,
        fragmentShader: globalMenuBGFragmentShader,
        side: THREE.FrontSide,
        transparent: true
      }
    )
    this.globalMenuBG = new THREE.Mesh(this.geometryGlobalMenuBG, this.materialGlobalMenuBG)
    this.globalMenuBG.position.z = 1
    this.globalMenuBG.name = 'globalMenuBG'

    this.scene.add(this.globalMenuBG)
  }

  textureLoaded () {
    const promises = []
    const textureloader = new THREE.TextureLoader()
    this.items = this.itemElements
    this.items.forEach((item, index) => {
      promises.push(
        this._loadTexture(textureloader,
          item.img ? item.img.src : null,
          index)
      )
    })

    return new Promise((resolve, reject) => {
      Promise.all(promises).then(promises => {
        promises.forEach((promise, index) => {
          this.items[index].texture = promise.texture
        })
        resolve()
      })
    })
  }

  get itemElements () {
    return this.allContents.map((item, index) => ({
      index,
      element: item,
      img: item.querySelector('img') || null
    }))
  }

  setMesh () {
    this.position = new THREE.Vector3(0, 0, 0)
    this.scale = new THREE.Vector3(1, 1, 1)
    this.geometry = new THREE.PlaneGeometry(1, 1, 10, 10)
    this.settings = {
      uStrength: 5
    }
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        uTexture: { value: null },
        uOffset: { value: new THREE.Vector2(0.0, 0.0) },
        uAlpha: { value: 0 },
        uTime: { value: 0 },
        uImage: { value: 0 },
        uTextureChangeTrigger: { value: 0 },
        uResolution: { value: new THREE.Vector2(this.config.width, this.config.height) },
        uRealSize: { value: null },
        uPlaneSizes: { value: null },
        uStrength: { value: this.settings.uStrength },
        uProgress: { value: 0 },
        uOpacity: { value: 1 },
        uCorners: { value: new THREE.Vector4(0.0, 0.0, 0.0, 0.0) },
        uYScale: { value: 1 },
        uYBottom: { value: 0 }
      },
      vertexShader,
      fragmentShader,
      transparent: true
    })
    this.plane = new THREE.Mesh(this.geometry, this.material)
    this.plane.name = 'horizontalPlane'
    this.material.uniforms.uRealSize.value = new THREE.Vector2(this.plane.geometry.parameters.width + this.plane.scale.x, this.plane.geometry.parameters.height + this.plane.scale.y)
    this.material.uniforms.uPlaneSizes.value = new THREE.Vector2(this.plane.geometry.parameters.width + this.plane.scale.x, this.plane.geometry.parameters.height + this.plane.scale.y)
    this.scene.add(this.plane)
  }

  addEventListeners () {
    this.items.forEach((item, index) => {
      item.element.addEventListener('pointerover', this._onMouseOver.bind(this, index), false)
    })

    this.contentmenuWrapper.addEventListener('pointerleave', this._onMouseLeave.bind(this), false)
    this.globalmenuWrapper.addEventListener('pointerleave', this._onMouseLeave.bind(this), false)
    this.homeWrapper.addEventListener('pointermove', this._onMouseMove.bind(this), false)
  }

  removeEventListeners () {
    this.items.forEach((item, index) => {
      item.element.removeEventListener('pointerover', this._onMouseOver.bind(this, index), false)
    })

    this.contentmenuWrapper.removeEventListener('pointerleave', this._onMouseLeave.bind(this), false)
    this.globalmenuWrapper.removeEventListener('pointerleave', this._onMouseLeave.bind(this), false)

    this.pageWrapper.removeEventListener('pointermove', this._onMouseMove.bind(this), false)
  }

  _loadTexture (loader, url, index) {
    return new Promise((resolve, reject) => {
      if (!url) {
        resolve({ texture: null, index })
        return
      }

      loader.load(
        url,
        texture => {
          resolve({ texture, index })
        },
        undefined,
        error => {
          console.error('texture load error happened.', error)
          reject(error)
        }
      )
    })
  }

  _map (inMin, inMax, outMin, outMax, value) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
  }

  _onMouseEnter () {
    if (!this.currentItem || !this.isMouseOver) {
      this.isMouseOver = true
      if (!this.isFading && !window.onAnchorScrolling) {
        gsap.to(this.plane.material.uniforms.uOpacity, {
          duration: 0.5,
          value: 1,
          ease: 'power4.out',
          onStart: () => {
            this.isFading = true
          },
          onComplete: () => {
            this.isFading = false
          }
        })
      }
    }
  }

  _onMouseOver (index, event) {
    if (this.plane) {
      if (!this.isLoaded) {
        return null
      }
      this._onMouseEnter()
      if (this.currentItem && this.currentItem.index === index) {
        return null
      }
      if (!window.onAnchorScrolling) {
        this._onTargetChange(index)
      }
    }
  }

  _onTargetChange (index) {
    this.planeWaveTl.restart()
    this.currentItem = this.items[index]
    if (!this.currentItem.texture) {
      return null
    }
    this.material.uniforms.uTexture.value = this.currentItem.texture

    this.imageRatio = this.currentItem.img.naturalWidth / this.currentItem.img.naturalHeight

    this.scale = new THREE.Vector3(this.imageRatio, 1, 1)

    if (index > 20) {
      const tl = gsap.timeline()
      tl.to(this.plane.scale, {
        duration: 0.7,
        x: this.currentItem.img.naturalWidth / 2 * this.mobileImageScale,
        y: this.currentItem.img.naturalHeight / 2 * this.mobileImageScale,
        ease: 'power1.out',
        onStart: () => {
          if (!this.planeWaveTl.isActive()) {
            this.planeWaveTl.to(this.plane.material.uniforms.uProgress, {
              duration: 2,
              value: 1,
              ease: CustomEase.create('custom', 'M0,0 C0.008,0.024 0.087,0.327 0.142,0.41 0.22,0.528 0.392,0.642 0.504,0.772 0.674,0.97 0.818,1.001 1,1 ')
            }, 0)
          }
        }
      })
    } else {
      const tl = gsap.timeline()
      tl.to(this.plane.scale, {
        duration: 0.7,
        x: this.currentItem.img.naturalWidth / 4 * this.mobileImageScale,
        y: this.currentItem.img.naturalHeight / 4 * this.mobileImageScale,
        ease: 'power1.out',
        onStart: () => {
          if (!this.planeWaveTl.isActive()) {
            this.planeWaveTl.to(this.plane.material.uniforms.uProgress, {
              duration: 2,
              value: 1,
              ease: CustomEase.create('custom', 'M0,0 C0.008,0.024 0.087,0.327 0.142,0.41 0.22,0.528 0.392,0.642 0.504,0.772 0.674,0.97 0.818,1.001 1,1 ')
            }, 0)
          }
        }
      })
    }
  }

  _onMouseLeave (event) {
    if (this.plane && !window.onAnchorScrolling) {
      gsap.to(this.plane.material.uniforms.uOpacity, {
        duration: 0.5,
        value: 0,
        ease: 'power4.out'
      })
    }
    this.isMouseOver = false
  }

  _onMouseMove (event) {
    // マウスの位置を取得及び正規化
    this.mouse.x = (event.clientX / this.config.width) * 2 - 1
    this.mouse.y = -(event.clientY / this.config.height) * 2 + 1
    let x
    let y
    if (this.mediaQuery.matches) {
      x = 0
      y = this.config.height / 5
    } else {
      x = this._map(-1, 1, -this.config.width / 2, this.config.width / 2, this.mouse.x)
      y = this._map(-1, 1, -this.config.height / 2, this.config.height / 2, this.mouse.y)
    }

    // `マウスの加速度を計算
    this.currentEvent = event
    this.speed = 0
    this.prevSpeed = 0

    if (this.currentEvent && this.prevEvent) {
      this.movementX = this.currentEvent.screenX - this.prevEvent.screenX
      this.speed = this.movementX
      this.acceleration = this.speed - this.prevSpeed
      this.prevSpeed = this.speed
      this.normalizeAcceleration = this._map(-150, 150, -1, 1, this.acceleration)
    }
    this.position = new THREE.Vector3(x, y, 0)
    if (this.plane) {
      gsap.to(this.plane.position, {
        x,
        y,
        ease: 'power4.out'
      })
      gsap.to(this.plane.rotation, {
        z: -this.normalizeAcceleration * (Math.PI / 3)
      })
    }
  }

  resize () {
    this.destroyForResize()
    this.horizontalScrollTl.scrollTrigger.refresh()
    this.horizontalScrollSlideTl.scrollTrigger.refresh()
    this.timelines.forEach(tl => {
      if (tl.scrollTrigger) {
        tl.scrollTrigger.refresh()
      }
    })
    this.setSettings()
    this.setMesh()
    this.setMenuBG()
  }

  update (elapsed) {
    if (this.plane) {
      this.prevEvent = this.currentEvent
      this.prevSpeed = this.speed
      this.plane.material.uniforms.uTime.value = elapsed
      this.plane.material.uniforms.uPlaneSizes.value.x = this.plane.geometry.parameters.width * this.plane.scale.x
      this.plane.material.uniforms.uPlaneSizes.value.y = this.plane.geometry.parameters.height * this.plane.scale.y
      this.plane.material.uniforms.uRealSize.value.x = this.plane.geometry.parameters.width * this.plane.scale.x
      this.plane.material.uniforms.uRealSize.value.y = this.plane.geometry.parameters.height * this.plane.scale.y
    }

    if (this.currentEvent && this.prevEvent) {
      this.movementX = this.currentEvent.screenX - this.prevEvent.screenX
      this.speed = this.movementX
      this.acceleration = this.speed - this.prevSpeed
      this.prevSpeed = this.speed
      this.normalizeAcceleration = this._map(-150, 150, -1, 1, this.acceleration)
    }

    if (window.isMenuOpen && !window.onAnchorScrolling) {
      let scrollHoverElement = null
      scrollHoverElement = document.elementsFromPoint(this.pointer.x, this.pointer.y).find(element => element.classList.contains('js-menulist-item'))
      if (scrollHoverElement) {
        if (!(this.currentMenuIndex === scrollHoverElement.dataset.menuIndex)) {
          this.menuContents.forEach((item, index) => {
            if (item === scrollHoverElement) {
              item.style.opacity = 1
              this._onMouseOver(index + 21)
            } else {
              item.style.opacity = 0.5
            }
          })
        }
        this.currentMenuIndex = scrollHoverElement.dataset.menuIndex
      } else {
        this._onMouseLeave()
      }
    }
  }

  destroyForResize () {
    this.scene.remove(this.plane)
    this.plane = null
    this.scene.remove(this.globalMenuBG)
    this.globalMenuBG = null
  }

  destroy () {
    this.timelines.forEach(tl => {
      tl.kill()
      tl = null
    })
    this.horizontalScrollTl.kill()
    this.horizontalScrollTl = null
    this.horizontalScrollSlideTl.kill()
    this.horizontalScrollSlideTl = null

    this.timelines = []
    this.scene.remove(this.plane)
    this.plane = null
    this.scene.remove(this.globalMenuBG)
    this.removeEventListeners()
  }
}
