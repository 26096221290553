import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '../SplitText'
gsap.registerPlugin(ScrollTrigger)

export default class CardFolding {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.mediaQueryHeightShort = window.matchMedia('(max-height: 40rem)')
    this.timelines = []
    this.pageWrapper = document.querySelector('[data-scroll]')
    this.contents = document.querySelectorAll('.js-principles__card')
    this.contentWrapper = document.querySelector('.js-principles__cardsContainer')
    this.nextSection = document.querySelector('.lastquote')
    this.setScrollTrigger()
  }

  setScrollTrigger () {
    this.cards = Array.from(document.querySelectorAll('.js-principles__card'))
    this.cards.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.titles = Array.from(document.querySelectorAll('.js-card__upTitleText'))
    this.titles.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.bottomTitles = Array.from(document.querySelectorAll('.js-card__bottomTitle'))
    this.bottomTitles.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.cardImageContainers = Array.from(document.querySelectorAll('.js-card__imageContainer'))
    this.cardImageContainers.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    const titleDuration = 1.0
    const titleFadeInDuration = 1.2
    const bottomTitleDuration = 0.8
    const bottomTitleFadeInDuration = 1.0
    const bottomTitleDelay = 0.2
    const cardDuration = 1
    const cardDelay = 0.5
    const cardEase = 'power3.out'
    const coverDuration = 0.3

    // カード１枚目
    this.cardImages1 = this.cardImageContainers[0].querySelectorAll('.js-card__image')
    this.cardVerticalDescriptions1 = Array.from(this.cards[0].querySelectorAll('.js-card__upDescription>.line>p'))
    this.cardVerticalDescriptions1.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.cardHorizontalDescriptions1 = Array.from(this.cards[0].querySelectorAll('.js-card__bottomDescription>.line>p'))
    this.cardHorizontalDescriptions1.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })

    // カード2枚目
    this.cardImages2 = this.cardImageContainers[1].querySelectorAll('.js-card__image')
    this.cardVerticalDescriptions2 = Array.from(this.cards[1].querySelectorAll('.js-card__upDescription>.line>p'))
    this.cardVerticalDescriptions2.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.cardHorizontalDescriptions2 = Array.from(this.cards[1].querySelectorAll('.js-card__bottomDescription>.line>p'))
    this.cardHorizontalDescriptions2.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })

    // カード3枚目
    this.cardImages3 = this.cardImageContainers[2].querySelectorAll('.js-card__image')
    this.cardVerticalDescriptions3 = Array.from(this.cards[2].querySelectorAll('.js-card__upDescription>.line>p'))
    this.cardVerticalDescriptions3.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.cardHorizontalDescriptions3 = Array.from(this.cards[2].querySelectorAll('.js-card__bottomDescription>.line>p'))
    this.cardHorizontalDescriptions3.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    // カード4枚目
    this.cardImages4 = this.cardImageContainers[3].querySelectorAll('.js-card__image')
    this.cardVerticalDescriptions4 = Array.from(this.cards[3].querySelectorAll('.js-card__upDescription>.line>p'))
    this.cardVerticalDescriptions4.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    this.cardHorizontalDescriptions4 = Array.from(this.cards[3].querySelectorAll('.js-card__bottomDescription>.line>p'))
    this.cardHorizontalDescriptions4.forEach(item => {
      ScrollTrigger.saveStyles(item)
    })
    // 次のセクション
    const lastQuoteSectionBorder = document.querySelector('.js-lastquote>hr')
    const lastQuoteUpContainer = document.querySelector('.js-lastquote__upContainer')
    const lastQuoteUpTexts = Array.from(lastQuoteUpContainer.querySelectorAll('.js-lastquote__upMessageText'))
    const lastQuoteCaptionBorder = document.querySelector('.js-lastquote__upCaptionBorder ')
    const lastQuoteBottomContainer = document.querySelector('.js-lastquote__bottomContainer')
    const lastQuoteBottomTexts = Array.from(lastQuoteBottomContainer.querySelectorAll('.js-lastquote__bottomMessageText'))
    const lastQuoteBottomDuration = 0.8
    const lastQuoteBottomFadeInDuration = 1.0
    let isFadingNext = false
    let isFadingNext2 = false

    const cardVerticalDescriptions1SplitTexts = []
    const cardVerticalDescriptions2SplitTexts = []
    const cardVerticalDescriptions3SplitTexts = []
    const cardVerticalDescriptions4SplitTexts = []

    ScrollTrigger.matchMedia({
      '(min-width: 68.76rem)': () => {
        // カード共通 事前準備
        const cardTimelines = []
        const covers = document.querySelectorAll('.js-card__cover')
        covers.forEach((cover, index) => {
          if (index !== 0) {
            gsap.set(cover, {
              opacity: 0.7
            })
          }
        })
        this.titles.forEach((title) => {
          gsap.set(title, {
            y: '100%',
            opacity: 0
          })
        })

        this.bottomTitles.forEach((title) => {
          gsap.set(title, {
            y: '100%',
            opacity: 0
          })
        })

        const titles = this.titles
        const bottomTitles = this.bottomTitles

        // カード１枚目 事前準備
        const cardImages1 = this.cardImages1
        const cardHorizontalDescriptions1 = this.cardHorizontalDescriptions1
        let isFading1 = false

        if (cardVerticalDescriptions1SplitTexts.length === 0) {
          this.cardVerticalDescriptions1.forEach((description, index) => {
            const splitText = new SplitText(description, false, true)
            splitText.setAnimateVerticalText(1)
            cardVerticalDescriptions1SplitTexts.push(splitText)
          })
        }

        cardVerticalDescriptions1SplitTexts.forEach((splitText, index) => {
          splitText.setAnimateVerticalText(1)
        })

        this.cardHorizontalDescriptions1.forEach((description, index) => {
          gsap.set(description, {
            y: '100%',
            opacity: 0
          })
        })

        gsap.set(this.cardImages1[0], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages1[1], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages1[2], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        // カード2枚目 事前準備
        const cardTl2 = gsap.timeline()
        const cardImages2 = this.cardImages2
        const cardHorizontalDescriptions2 = this.cardHorizontalDescriptions2
        let isFading2 = false
        let isCard2Animating = false

        if (cardVerticalDescriptions2SplitTexts.length === 0) {
          this.cardVerticalDescriptions2.forEach((description, index) => {
            const splitText = new SplitText(description, false, true)
            splitText.setAnimateVerticalText(1)
            cardVerticalDescriptions2SplitTexts.push(splitText)
          })
        }

        cardVerticalDescriptions2SplitTexts.forEach((splitText, index) => {
          splitText.setAnimateVerticalText(1)
        })

        this.cardHorizontalDescriptions2.forEach((description, index) => {
          gsap.set(description, {
            y: '100%',
            opacity: 0
          })
        })

        gsap.set(this.cardImages2[0], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages2[1], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages2[2], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        // カード3枚目 事前準備
        const cardTl3 = gsap.timeline()
        const cardImages3 = this.cardImages3
        const cardHorizontalDescriptions3 = this.cardHorizontalDescriptions3
        let isFading3 = false
        let isCard3Animating = false

        if (cardVerticalDescriptions3SplitTexts.length === 0) {
          this.cardVerticalDescriptions3.forEach((description, index) => {
            const splitText = new SplitText(description, false, true)
            splitText.setAnimateVerticalText(1)
            cardVerticalDescriptions3SplitTexts.push(splitText)
          })
        }

        cardVerticalDescriptions3SplitTexts.forEach((splitText, index) => {
          splitText.setAnimateVerticalText(1)
        })

        this.cardHorizontalDescriptions3.forEach((description, index) => {
          gsap.set(description, {
            y: '100%',
            opacity: 0
          })
        })

        gsap.set(this.cardImages3[0], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages3[1], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages3[2], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        // カード4枚目 事前準備
        const cardTl4 = gsap.timeline()
        const cardImages4 = this.cardImages4
        const cardHorizontalDescriptions4 = this.cardHorizontalDescriptions4
        let isFading4 = false
        let isCard4Animating = false

        if (cardVerticalDescriptions4SplitTexts.length === 0) {
          this.cardVerticalDescriptions4.forEach((description, index) => {
            const splitText = new SplitText(description, false, true)
            splitText.setAnimateVerticalText(1)
            cardVerticalDescriptions4SplitTexts.push(splitText)
          })
        }

        cardVerticalDescriptions4SplitTexts.forEach((splitText, index) => {
          splitText.setAnimateVerticalText(1)
        })

        this.cardHorizontalDescriptions4.forEach((description, index) => {
          gsap.set(description, {
            y: '100%',
            opacity: 0
          })
        })

        gsap.set(this.cardImages4[0], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages4[1], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        gsap.set(this.cardImages4[2], {
          transform: 'translate3d(-3vw, 0vw, 0vw)',
          opacity: 0
        })

        // 次のセクション 事前準備
        gsap.set(lastQuoteSectionBorder, {
          opacity: 0.2
        })

        lastQuoteUpContainer.splitTexts = []
        if (lastQuoteUpContainer.splitTexts.length === 0) {
          lastQuoteUpTexts.forEach((text, index) => {
            const splitText = new SplitText(text, false)
            lastQuoteUpContainer.splitTexts.push(splitText)
          })
        }

        lastQuoteUpContainer.splitTexts.forEach((splitText, index) => {
          splitText.setAnimateVerticalText()
        })

        gsap.set(lastQuoteCaptionBorder, {
          opacity: 0
        })

        lastQuoteBottomTexts.forEach((text, index) => {
          gsap.set(text, {
            y: '100%',
            opacity: 0
          })
        })

        //  １枚目アニメーション
        this.firstCardTl = gsap.timeline({
          scrollTrigger: {
            scroller: this.pageWrapper,
            trigger: this.contentWrapper,
            start: 'top center',
            toggleActions: 'play none none reverse'
          }
        })

        this.firstCardTl.to(titles[0], {
          y: '0%',
          duration: titleDuration,
          delay: 0.2,
          ease: 'power3.out',
          onStart: () => {
            isFading1 = true
            cardVerticalDescriptions1SplitTexts.forEach((splitText, index) => {
              if (splitText.verticalTl.paused()) {
                splitText.replayVerticalText()
                return
              }
              splitText.animateVerticalText(0.08, index)
            })
          },
          onReverseComplete: () => {
            cardVerticalDescriptions1SplitTexts.forEach((splitText, index) => {
              splitText.resetVerticalText()
            })
          }
        })
          .to(titles[0], {
            opacity: 1,
            delay: 0.2,
            duration: titleFadeInDuration,
            ease: 'none'
          }, 0)
        this.firstCardTl.to(bottomTitles[0], {
          y: '0%',
          duration: bottomTitleDuration,
          delay: bottomTitleDelay,
          ease: 'power3.out'
        }, 0)
          .to(bottomTitles[0], {
            duration: bottomTitleFadeInDuration,
            delay: bottomTitleDelay,
            opacity: 1,
            ease: 'none'
          }, 0)
        cardHorizontalDescriptions1.forEach((description, index) => {
          this.firstCardTl.to(description, {
            y: '0%',
            delay: bottomTitleDelay + 0.2 * (index + 1),
            duration: 0.8,
            ease: 'power3.out'
          }, 0)
            .to(description, {
              duration: 1.0,
              delay: bottomTitleDelay + 0.2 * (index + 1),
              opacity: 1,
              ease: 'none',
              onComplete: () => {
                isFading1 = false
              }
            }, 0)
        })

        this.firstCardTl.to(cardImages1[0], {
          opacity: 1,
          duration: cardDuration,
          ease: cardEase,
          delay: cardDelay,
          transform: 'translate3d(0.5vw, 0vw, 1vw)'
        }, 0)
          .to(cardImages1[1], {
            opacity: 1,
            duration: cardDuration + 0.2,
            delay: cardDelay,
            ease: cardEase,
            transform: 'translate3d(5.21875vw, 0vw, 1vw)'
          }, 0)
          .to(cardImages1[2], {
            opacity: 1,
            duration: cardDuration + 0.4,
            delay: cardDelay,
            ease: cardEase,
            transform: 'translate3d(9.43625vw, 0vw, 1vw)'
          }, 0)

        //  ２枚目以降アニメーション
        if (this.mediaQuery.matches) {
          this.tl = gsap.timeline({
            scrollTrigger: {
              scroller: this.pageWrapper,
              scrub: 1,
              trigger: this.contentWrapper,
              pin: true,
              start: 'top top',
              end: '+=250%',
              toggleActions: 'play none none reset'
            }
          })
        } else {
          this.tl = gsap.timeline({
            scrollTrigger: {
              scroller: this.pageWrapper,
              scrub: 0.5,
              trigger: this.contentWrapper,
              pin: true,
              start: 'top top',
              end: '+=450%',
              toggleActions: 'play none none reset'
            }
          })
        }

        this.tl.to(this.contents[1], {
          top: '3.25vh',
          ease: 'none',
          onReverseComplete: () => {
            cardTl2.pause(0)
            cardVerticalDescriptions2SplitTexts.forEach((splitText, index) => {
              splitText.resetVerticalText()
            })
          },
          onUpdate: function () {
            if (cardTl2.paused() && this.progress() > 0.2) {
              cardTl2.play()
              cardVerticalDescriptions2SplitTexts.forEach((splitText, index) => {
                splitText.replayVerticalText()
              })
              return
            }
            if (this.progress() > 0.2 && !isFading2 && !isCard2Animating && !cardTl2.isActive()) {
              isCard2Animating = true
              cardTl2.to(titles[1], {
                y: '0%',
                duration: titleDuration,
                delay: 0.2,
                ease: 'power3.out',
                onStart: () => {
                  isFading2 = true
                }
              })
                .to(titles[1], {
                  opacity: 1,
                  delay: 0.2,
                  duration: titleFadeInDuration,
                  ease: 'none'
                }, 0)
              cardVerticalDescriptions2SplitTexts.forEach((splitText, index) => {
                splitText.animateVerticalText(0.08, index)
              })
              cardTl2.to(bottomTitles[1], {
                y: '0%',
                duration: bottomTitleDuration,
                delay: bottomTitleDelay,
                ease: 'power3.out'
              }, 0)
                .to(bottomTitles[1], {
                  duration: bottomTitleFadeInDuration,
                  delay: bottomTitleDelay,
                  opacity: 1,
                  ease: 'none'
                }, 0)
              cardHorizontalDescriptions2.forEach((description, index) => {
                cardTl2.to(description, {
                  y: '0%',
                  delay: bottomTitleDelay + 0.2 * (index + 1),
                  duration: 0.8,
                  ease: 'power3.out'
                }, 0)
                  .to(description, {
                    duration: 1.0,
                    delay: bottomTitleDelay + 0.2 * (index + 1),
                    opacity: 1,
                    ease: 'none',
                    onComplete: () => {
                      isFading2 = false
                    }
                  }, 0)
              })
              cardTl2.to(cardImages2[0], {
                opacity: 1,
                duration: cardDuration,
                ease: cardEase,
                delay: cardDelay,
                transform: 'translate3d(0.5vw, 0vw, 1vw)'
              }, 0)
                .to(cardImages2[1], {
                  opacity: 1,
                  transform: 'translate3d(5.21875vw, 0vw, 1vw)',
                  duration: cardDuration + 0.2,
                  delay: cardDelay,
                  ease: cardEase
                }, 0)
                .to(cardImages2[2], {
                  opacity: 1,
                  transform: 'translate3d(9.43625vw, 0vw, 1vw)',
                  duration: cardDuration + 0.4,
                  delay: cardDelay,
                  ease: cardEase
                }, 0)
              cardTimelines.push(cardTl2)
            }
          }
        }, 'first')
          .to(covers[1], {
            opacity: 0,
            duration: coverDuration,
            ease: 'none'
          }, 'first')
          .to(covers[0], {
            opacity: 0.7,
            duration: coverDuration,
            ease: 'none'
          }, 'first')
          .to(this.contents[2], {
            top: '6.5vh',
            ease: 'none',
            onReverseComplete: () => {
              cardTl3.pause(0)
              cardVerticalDescriptions3SplitTexts.forEach((splitText, index) => {
                splitText.resetVerticalText()
              })
            },
            onUpdate: function () {
              if (cardTl3.paused() && this.progress() > 0.2) {
                cardTl3.play()
                cardVerticalDescriptions3SplitTexts.forEach((splitText, index) => {
                  splitText.replayVerticalText()
                })
                return
              }
              if (this.progress() > 0.2 && !isFading3 && !isCard3Animating && !cardTl3.isActive()) {
                isCard3Animating = true
                cardTl3.to(titles[2], {
                  y: '0%',
                  duration: titleDuration,
                  delay: 0.2,
                  ease: 'power3.out',
                  onStart: () => {
                    isFading2 = true
                  }
                })
                  .to(titles[2], {
                    opacity: 1,
                    delay: 0.2,
                    duration: titleFadeInDuration,
                    ease: 'none'
                  }, 0)
                cardVerticalDescriptions3SplitTexts.forEach((splitText, index) => {
                  splitText.animateVerticalText(0.08, index)
                })
                cardTl3.to(bottomTitles[2], {
                  y: '0%',
                  duration: bottomTitleDuration,
                  delay: bottomTitleDelay,
                  ease: 'power3.out'
                }, 0)
                  .to(bottomTitles[2], {
                    duration: bottomTitleFadeInDuration,
                    delay: bottomTitleDelay,
                    opacity: 1,
                    ease: 'none'
                  }, 0)
                cardHorizontalDescriptions3.forEach((description, index) => {
                  cardTl3.to(description, {
                    y: '0%',
                    delay: bottomTitleDelay + 0.2 * (index + 1),
                    duration: 0.8,
                    ease: 'power3.out'
                  }, 0)
                    .to(description, {
                      duration: 1.0,
                      delay: bottomTitleDelay + 0.2 * (index + 1),
                      opacity: 1,
                      ease: 'none',
                      onComplete: () => {
                        isFading3 = false
                      }
                    }, 0)
                })
                cardTl3.to(cardImages3[0], {
                  opacity: 1,
                  duration: cardDuration,
                  ease: cardEase,
                  delay: cardDelay,
                  transform: 'translate3d(0.5vw, 0vw, 1vw)'
                }, 0)
                  .to(cardImages3[1], {
                    opacity: 1,
                    transform: 'translate3d(5.21875vw, 0vw, 1vw)',
                    duration: cardDuration + 0.2,
                    delay: cardDelay,
                    ease: cardEase
                  }, 0)
                  .to(cardImages3[2], {
                    opacity: 1,
                    transform: 'translate3d(9.43625vw, 0vw, 1vw)',
                    duration: cardDuration + 0.4,
                    delay: cardDelay,
                    ease: cardEase
                  }, 0)
                cardTimelines.push(cardTl3)
              }
            }
          }, 'second')
          .to(covers[2], {
            opacity: 0,
            duration: coverDuration,
            ease: 'none'
          }, 'second')
          .to(covers[1], {
            opacity: 0.7,
            ease: 'none'
          }, 'second')
          .to(this.contents[3], {
            top: '9.75vh',
            ease: 'none',
            onReverseComplete: () => {
              cardTl4.pause(0)
              cardVerticalDescriptions4SplitTexts.forEach((splitText, index) => {
                splitText.resetVerticalText()
              })
            },
            onUpdate: function () {
              if (cardTl4.paused() && this.progress() > 0.2) {
                cardTl4.play()
                cardVerticalDescriptions4SplitTexts.forEach((splitText, index) => {
                  splitText.replayVerticalText()
                })
                return
              }
              if (this.progress() > 0.2 && !isFading4 && !isCard4Animating && !cardTl4.isActive()) {
                isCard4Animating = true
                cardTl4.to(titles[3], {
                  y: '0%',
                  duration: titleDuration,
                  delay: 0.2,
                  ease: 'power3.out',
                  onStart: () => {
                    isFading2 = true
                  }
                })
                  .to(titles[3], {
                    opacity: 1,
                    delay: 0.2,
                    duration: titleFadeInDuration,
                    ease: 'none'
                  }, 0)
                cardVerticalDescriptions4SplitTexts.forEach((splitText, index) => {
                  splitText.animateVerticalText(0.08, index)
                })
                cardTl4.to(bottomTitles[3], {
                  y: '0%',
                  duration: bottomTitleDuration,
                  delay: bottomTitleDelay,
                  ease: 'power3.out'
                }, 0)
                  .to(bottomTitles[3], {
                    duration: bottomTitleFadeInDuration,
                    delay: bottomTitleDelay,
                    opacity: 1,
                    ease: 'none'
                  }, 0)
                cardHorizontalDescriptions4.forEach((description, index) => {
                  cardTl4.to(description, {
                    y: '0%',
                    delay: bottomTitleDelay + 0.2 * (index + 1),
                    duration: 0.8,
                    ease: 'power3.out'
                  }, 0)
                    .to(description, {
                      duration: 1.0,
                      delay: bottomTitleDelay + 0.2 * (index + 1),
                      opacity: 1,
                      ease: 'none',
                      onComplete: () => {
                        isFading4 = false
                      }
                    }, 0)
                })
                cardTl4.to(cardImages4[0], {
                  opacity: 1,
                  duration: cardDuration,
                  ease: cardEase,
                  delay: cardDelay,
                  transform: 'translate3d(0.5vw, 0vw, 1vw)'
                }, 0)
                  .to(cardImages4[1], {
                    opacity: 1,
                    transform: 'translate3d(5.21875vw, 0vw, 1vw)',
                    duration: cardDuration + 0.2,
                    delay: cardDelay,
                    ease: cardEase
                  }, 0)
                  .to(cardImages4[2], {
                    opacity: 1,
                    transform: 'translate3d(9.43625vw, 0vw, 1vw)',
                    duration: cardDuration + 0.4,
                    delay: cardDelay,
                    ease: cardEase
                  }, 0)
                cardTimelines.push(cardTl4)
              }
            }
          }, 'third')
          .to(covers[3], {
            opacity: 0,
            ease: 'none'
          }, 'third')
          .to(covers[2], {
            opacity: 0.7,
            ease: 'none'
          }, 'third')
          .to(this.nextSection, {
            top: '14.5vh',
            ease: 'none',
            onUpdate: function () {
              if (this.progress() > 0.5 && !isFadingNext) {
                const tl = gsap.timeline({
                  onStart: () => {
                    isFadingNext = true
                  },
                  onComplete: () => {
                    isFadingNext = false
                  }
                })
                tl.to(lastQuoteCaptionBorder, {
                  opacity: 1,
                  duration: 0.5,
                  ease: 'none',
                  onStart: () => {
                    lastQuoteUpContainer.splitTexts.forEach((text, index) => {
                      text.animateVerticalText(0.05, index)
                    })
                  }
                })
              }
              if (this.progress() > 0.8 && !isFadingNext2) {
                const tl = gsap.timeline({
                  delay: 0.8,
                  onStart: () => {
                    isFadingNext2 = true
                  },
                  onComplete: () => {
                    isFadingNext2 = false
                  }
                })
                lastQuoteBottomTexts.forEach((text, index) => {
                  tl.to(text, {
                    y: '0%',
                    duration: lastQuoteBottomDuration,
                    delay: index * 0.2,
                    ease: 'power3.out'
                  }, 0)
                    .to(text, {
                      duration: lastQuoteBottomFadeInDuration,
                      delay: index * 0.2,
                      opacity: 1,
                      ease: 'none'
                    }, 0)
                })
              }
            }
          }, 'fourth')
          .to(covers[3], {
            opacity: 0.7,
            ease: 'none'
          }, 'fourth')
          .to(lastQuoteSectionBorder, {
            opacity: 0.5,
            ease: 'none'
          }, 'fourth')
        this.timelines.push(this.firstCardTl, this.tl)
      },
      '(max-width: 68.75rem)': () => {
        if (cardVerticalDescriptions1SplitTexts.length > 0) {
          cardVerticalDescriptions1SplitTexts.forEach(splitText => {
            splitText.resetAnimateVerticalText()
          })
        }
        if (cardVerticalDescriptions2SplitTexts.length > 0) {
          cardVerticalDescriptions2SplitTexts.forEach(splitText => {
            splitText.resetAnimateVerticalText()
          })
        }
        if (cardVerticalDescriptions3SplitTexts.length > 0) {
          cardVerticalDescriptions3SplitTexts.forEach(splitText => {
            splitText.resetAnimateVerticalText()
          })
        }
        if (cardVerticalDescriptions4SplitTexts.length > 0) {
          cardVerticalDescriptions4SplitTexts.forEach(splitText => {
            splitText.resetAnimateVerticalText()
          })
        }

        if (lastQuoteUpContainer.splitTexts) {
          lastQuoteUpContainer.splitTexts.forEach(splitText => {
            splitText.resetAnimateVerticalText()
          })
        }

        if (lastQuoteBottomTexts) {
          lastQuoteBottomTexts.forEach((text, index) => {
            gsap.set(text, {
              y: '0%',
              opacity: 1
            })
          })
        }
        gsap.set(this.cardImages1[0], {
          transform: 'translate3d(0.5vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages1[1], {
          transform: 'translate3d(5.21875vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages1[2], {
          transform: 'translate3d(9.43625vw, 0vw, 1vw)',
          opacity: 1
        })
        gsap.set(this.cardImages2[0], {
          transform: 'translate3d(0.5vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages2[1], {
          transform: 'translate3d(5.21875vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages2[2], {
          transform: 'translate3d(9.43625vw, 0vw, 1vw)',
          opacity: 1
        })
        gsap.set(this.cardImages3[0], {
          transform: 'translate3d(0.5vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages3[1], {
          transform: 'translate3d(5.21875vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages3[2], {
          transform: 'translate3d(9.43625vw, 0vw, 1vw)',
          opacity: 1
        })
        gsap.set(this.cardImages4[0], {
          transform: 'translate3d(0.5vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages4[1], {
          transform: 'translate3d(5.21875vw, 0vw, 1vw)',
          opacity: 1
        })

        gsap.set(this.cardImages4[2], {
          transform: 'translate3d(9.43625vw, 0vw, 1vw)',
          opacity: 1
        })
        gsap.set(lastQuoteCaptionBorder, {
          opacity: 1
        })
      }
    })
  }

  resize () {
  }

  destroy () {
    this.timelines.forEach(tl => tl.kill())
  }
}
