export default [
  {
    name: 'base',
    data: {},
    items:
        [
          { name: 'waterFlowTexture', source: '/assets/images/inbetween1@2x.png', type: 'texture' }
        ]
  }
]
