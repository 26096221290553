import '@scss/app';
import Transition from './Transition';
import Experience from './Experience/Experience.js';
import Scroll from './scroll';
import ScrollDetail from './scrollDetail';
import Pointer from './Pointer';
import Cursor from './Cursor';
import ClipBoard from './ClipBoard';
import { default as Experience2 } from './Experience2/Experience.js';
import { default as ExperienceDetail } from './ExperienceDetail/Experience.js';
export default class App {
    constructor() {
        if (App.instance) {
            return App.instance;
        }
        App.instance = this;
        this.mediaQuery = window.matchMedia('(max-width: 34.375rem)');
        this.initTransition();
        this.initExperience();
        window.addEventListener('DOMContentLoaded', () => {
            this.initPointer();
            if (window.location.pathname === '/') {
                this.initClipBoard();
            }
        });
        this.update();
    }
    initTransition() {
        this.transition = new Transition();
    }
    initExperience() {
        if (window.location.pathname === '/') {
            this.initScroll();
            if (!this.mediaQuery.matches) {
                this.initCursor();
            }
            this.experience = new Experience({
                targetElement: document.querySelector('.experience')
            });
            this.experience2 = new Experience2({
                targetElement: document.querySelector('.experienceHero')
            });
        }
        else {
            this.initScrollDetail();
            if (!this.mediaQuery.matches) {
                this.initCursor();
            }
            this.experienceDetail = new ExperienceDetail({
                targetElement: document.querySelector('.experienceDetail')
            });
        }
    }
    initClipBoard() {
        this.clipBoard = new ClipBoard();
    }
    destroyExperience() {
        this.destroyScroll();
        this.destroyCursor();
        if (this.experience) {
            this.experience.destroy();
            this.experience = null;
        }
        if (this.experience2) {
            this.experience2.destroy();
            this.experience2 = null;
        }
        if (this.experienceDetail) {
            this.experienceDetail.destroy();
            this.experienceDetail = null;
        }
    }
    initScroll() {
        this.scroll = new Scroll();
    }
    initScrollDetail() {
        this.scrollDetail = new ScrollDetail();
        this.scrollDetail.value.isHorizontal = true;
    }
    initPointer() {
        this.pointer = new Pointer();
    }
    initCursor() {
        const cursorDOM = document.getElementById('pointer');
        this.cursor = new Cursor(cursorDOM);
    }
    destroyScroll() {
        if (this.scroll) {
            this.scroll.destroy();
            this.scroll = null;
        }
        if (this.scrollDetail) {
            this.scrollDetail.destroy();
            this.scrollDetail = null;
        }
    }
    destroyCursor() {
        if (this.cursor) {
            this.cursor.destroy();
            this.cursor = null;
        }
    }
    toHorizontalScroll() {
        if (this.scroll) {
            this.scroll.value.isHorizontal = true;
        }
        if (this.scrollDetail) {
            this.scrollDetail.value.isHorizontal = true;
        }
    }
    toVerticalScroll() {
        if (this.scroll) {
            this.scroll.value.isHorizontal = false;
        }
        if (this.scrollDetail) {
            this.scrollDetail.value.isHorizontal = false;
        }
    }
    setDetailStartAnimation() {
        if (!this.experienceDetail)
            return;
        this.experienceDetail.setDetailEnter();
    }
    update() {
        var _a, _b, _c;
        if (this.scroll) {
            this.scroll.update();
        }
        if (this.scrollDetail) {
            this.scrollDetail.update();
        }
        if (this.cursor) {
            this.cursor.update();
        }
        (_a = this.experience) === null || _a === void 0 ? void 0 : _a.update();
        (_b = this.experience2) === null || _b === void 0 ? void 0 : _b.update();
        (_c = this.experienceDetail) === null || _c === void 0 ? void 0 : _c.update();
        this.raf = requestAnimationFrame(() => {
            this.update();
        });
    }
    destroy() {
        cancelAnimationFrame(this.raf);
    }
}
new App();
