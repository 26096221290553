import * as THREE from 'three'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Experience from './Experience.js'
import Scroll from '../scroll'
import archivesBGVertexShader from './Shaders/archives/vertexShader.glsl'
import archivesBGFragmentShader from './Shaders/archives/fragmentShader.glsl'

gsap.registerPlugin(ScrollTrigger)

window.canvasOnScroll = true
export default class Background {
  constructor () {
    this.experience = new Experience()
    this.scroll = new Scroll()
    this.config = this.experience.config
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.camera = this.experience.camera.instance
    this.currentScroll = this.scroll.value.current

    this.pageWrapper = document.querySelector('div[data-scroll]')
    this.contentWrapper = document.querySelector('.js-horizontal-scroll')

    this.init()
  }

  init () {
    // archivesセクションBG
    this.geometryArchivesBG = new THREE.PlaneGeometry(this.config.width * 3, this.config.height * 3, 30, 30)

    this.materialArchivesBG = new THREE.ShaderMaterial(
      {
        uniforms: {
          uResolution: { value: new THREE.Vector2(this.config.width, this.config.height) },
          uAlpha: { value: 0 }
        },
        vertexShader: archivesBGVertexShader,
        fragmentShader: archivesBGFragmentShader,
        side: THREE.FrontSide,
        transparent: true
      }
    )
    this.archivesBG = new THREE.Mesh(this.geometryArchivesBG, this.materialArchivesBG)
    this.archivesBG.position.z = -0.01
    this.archivesBG.name = 'archivesBG'

    this.scene.add(this.archivesBG)
    this.setSettings()
    this.setScrollTrigger()
  }

  setSettings () {
    this.settings = {
      progress: 0,
      speedX: 0.4,
      speedY: 0.3,
      smallNoiseSize: 316,
      smallNoiseParameter: 24.7,
      bigNoiseParameter: 0.1,
      bigNoiseSize: 0.83,
      noiseG: 0,
      strokeNumber: 0,
      mainColor: new THREE.Color('rgb(192, 119, 17)'),
      subColor: new THREE.Color('rgb(7, 3, 3)')
    }
    this.settingsBackground = {
      BGprogress: 0,
      BGspeedX: 0.4,
      BGspeedY: 0.3,
      BGsmallNoiseSize: 2900,
      BGsmallNoiseParameter: 24.7,
      BGbigNoiseParameter: 2.2,
      BGbigNoiseSize: 0.83,
      BGnoiseG: 0,
      BGstrokeNumber: 0,
      BGmainColor: new THREE.Color('rgb(255, 255, 255)'),
      BGsubColor: new THREE.Color('rgb(24, 24, 24)')
    }
  }

  setScrollTrigger () {
    this.backgroundFadeInTl = gsap.timeline({
      scrollTrigger: {
        scroller: this.pageWrapper,
        trigger: this.contentWrapper,
        start: 'top top+=1%',
        onEnter: () => {
          this.experience.camera.instance.position.x = 0
          this.experience.camera.instance.position.y = 0
          this.experience.camera.isCameraMoveActive = false
        },
        onLeaveBack: () => {
          this.experience.camera.instance.position.x = 0
          this.experience.camera.instance.position.y = 0
          this.experience.camera.isCameraMoveActive = true
          if (!window.onAnchorScrolling) {
            gsap.to(this.archivesBG.material.uniforms.uAlpha, {
              duration: 0.5,
              value: 0,
              ease: 'expo.out'
            })
          }
        },
        markers: false
      }
    })

    this.backgroundFadeOutTl = gsap.timeline({
      scrollTrigger: {
        scroller: this.pageWrapper,
        trigger: this.contentWrapper,
        start: 'bottom-=5% top+=1%',
        end: 'bottom bottom',
        toggleActions: 'play none none reverse',
        onEnter: () => {
          this.experience.camera.instance.position.x = 0
          this.experience.camera.instance.position.y = 0
          this.experience.camera.isCameraMoveActive = false
          if (!window.onAnchorScrolling) {
            gsap.to(this.archivesBG.material.uniforms.uAlpha, {
              duration: 0.5,
              value: 0,
              ease: 'expo.out'
            })
          }
        },
        onLeaveBack: () => {
          this.experience.camera.instance.position.x = 0
          this.experience.camera.instance.position.y = 0
        }
      }
    })
  }

  setPosition () {
    this.background.position.y = this.currentScroll - this.config.height / 2 - (this.fullPageHeight / 2)
  }

  resize () {
    this.destroy()
    this.init()
  }

  update (elapsed) {
    this.currentScroll = this.scroll.value.current
    if (window.canvasOnScroll && this.background) {
      this.setPosition()
    }

    if (window.canvasOnScroll && this.archivesBG.material.uniforms.uAlpha.value !== 0) {
      gsap.to(this.archivesBG.material.uniforms.uAlpha, {
        duration: 0.5,
        value: 0,
        ease: 'expo.out'
      })
    }
    if (!window.canvasOnScroll && this.archivesBG.material.uniforms.uAlpha.value === 0) {
      gsap.to(this.archivesBG.material.uniforms.uAlpha, {
        duration: 0.5,
        value: 1,
        ease: 'expo.out'
      })
    }
  }

  destroy () {
    this.scene.remove(this.archivesBG)

    this.backgroundFadeInTl.kill()
    this.backgroundFadeOutTl.kill()
    this.backgroundFadeInTl = null
    this.backgroundFadeOutTl = null
  }
}
