import gsap from 'gsap'
import Experience from './Experience.js'
import Scroll from '../scrollDetail'

export default class DetailEnter {
  constructor () {
    this.init()
  }

  async init () {
    this.mediaQuery = window.matchMedia('(max-width: 68.75rem)')
    this.experience = new Experience()
    this.scroll = new Scroll()

    this.pageWrapper = document.querySelector('div[data-scroll]')
    this.backButton = document.querySelector('.js-detail__backButton')
    this.backButtonLines = Array.from(this.backButton.querySelectorAll('.line p'))
    this.mainTitle = document.querySelector('.js-detail-firstView__mainTitle')
    this.mainTitleLines = Array.from(this.mainTitle.querySelectorAll('.line>p'))
    this.mainDescriptions = document.querySelector('.js-detail-firstView__descriptions')
    this.mainDescriptionLines = Array.from(this.mainDescriptions.querySelectorAll('.js-detail-firstView__description>.line>p'))
    this.mainViewSign = document.querySelector('.js-detail-firstView__sign')
    this.mainViewSignLines = Array.from(this.mainViewSign.querySelectorAll('.line>p'))
    this.mainHero = document.querySelector('.js-detail-firstView__hero ')

    this.lastDescriptions = document.querySelector('.js-detail-lastView__descriptions')
    this.lastDescriptionLines = Array.from(this.lastDescriptions.querySelectorAll('.line>p'))
    this.lastTitle = document.querySelector('.js-detail-lastView__nextTitle')
    this.lastTitleLines = Array.from(this.lastTitle.querySelectorAll('.line>p'))
    this.lastCaption = document.querySelector('.js-detail-lastView__nextCaption')
    this.lastCaptionLine = this.lastCaption.querySelectorAll('.line>p')

    setTimeout(() => {
      this.heroMesh = this.experience.imageDistortion.imageStore[0].mesh
      this.setFadeInAnimation()
      this.setScrollTrigger()
    }, 400)

    this.timelines = []
  }

  setFadeInAnimation () {
    if (!this.mediaQuery.matches) {
      this.experience.isLoading = true
      this.scroll.value.isActive = false
    }
    const titleDuration = 0.8
    const titleDelay = 0.2
    const descDuration = 0.4
    const descDelay = 0.1
    const signDuration = 0.4
    const signDelay = 0.1

    const tl = gsap.timeline({})
    this.mainTitleLines.forEach((line, index) => {
      tl.from(line, {
        y: '100%',
        duration: titleDuration,
        delay: index * titleDelay,
        ease: 'expo.out'
      }, 0)
        .from(line, {
          duration: titleDuration,
          opacity: 0,
          delay: index * titleDelay,
          ease: 'none'
        }, 0
        )
    })
    this.mainDescriptionLines.forEach((line, index) => {
      tl.from(line, {
        y: '100%',
        duration: descDuration,
        delay: index * descDelay,
        ease: 'expo.out'
      }, 0.4)
        .from(line, {
          duration: descDuration,
          opacity: 0,
          delay: index * descDelay,
          ease: 'none'
        }, 0.4
        )
    })
    this.mainViewSignLines.forEach((line, index) => {
      tl.from(line, {
        y: '100%',
        duration: signDuration,
        delay: index * signDelay,
        ease: 'expo.out'
      }, 0.6)
        .from(line, {
          duration: signDuration,
          opacity: 0,
          delay: index * signDelay,
          ease: 'none'
        }, 0.6
        )
    })
    this.backButtonLines.forEach((line, index) => {
      if (index === 0 || index === 1) {
        tl.from(line, {
          y: '100%',
          duration: signDuration,
          delay: index * signDelay,
          ease: 'expo.out'
        }, 0.8)
          .from(line, {
            duration: signDuration,
            opacity: 0,
            delay: index * signDelay,
            ease: 'none'
          }, 0.8
          )
      }
    })
    if (!this.mediaQuery.matches) {
      tl.from(this.heroMesh.position, {
        duration: 2,
        x: `${this.heroMesh.position.x + this.heroMesh.geometry.parameters.width}`,
        ease: 'expo.out'
      }, 0.4)
        .from(this.heroMesh.material.uniforms.uTransparency, {
          duration: 2.2,
          value: '0',
          ease: 'none'
        }, 0.4)
    }
    tl.then(() => {
      this.experience.isLoading = false
      this.scroll.value.isActive = true
    })

    this.timelines.push(tl)
  }

  setScrollTrigger () {
    const titleDuration = 0.8
    const titleDelay = 0.2
    const captionDuration = 0.4
    const captionDelay = 0.1
    const descTl = gsap.timeline({
      scrollTrigger: {
        scroller: this.pageWrapper,
        trigger: this.lastDescriptions,
        start: 'left center+=30%',
        toggleActions: 'play none none reverse',
        horizontal: true
      }
    })
    this.lastDescriptionLines.forEach((line, index) => {
      descTl.from(line, {
        y: '100%',
        duration: 0.4,
        delay: index * 0.1,
        ease: 'expo.out'
      }, 0)
        .from(line, {
          duration: 0.4,
          opacity: 0,
          delay: index * 0.1,
          ease: 'none'
        }, 0
        )
    })
    const titleTl = gsap.timeline({
      scrollTrigger: {
        scroller: this.pageWrapper,
        trigger: this.lastTitle,
        start: 'left center+=30%',
        toggleActions: 'play none none reverse',
        horizontal: true
      }
    })
    this.lastTitleLines.forEach((line, index) => {
      titleTl.from(line, {
        y: '100%',
        duration: titleDuration,
        delay: index * titleDelay,
        ease: 'expo.out'
      }, 0)
        .from(line, {
          duration: titleDuration,
          opacity: 0,
          delay: index * titleDelay,
          ease: 'none'
        }, 0
        )
    })
    this.lastCaptionLine.forEach((line, index) => {
      titleTl.from(line, {
        y: '100%',
        duration: captionDuration,
        delay: index * captionDelay,
        ease: 'expo.out'
      }, 0.4)
        .from(line, {
          duration: captionDuration,
          opacity: 0,
          delay: index * captionDelay,
          ease: 'none'
        }, 0.4
        )
    })
    this.timelines.push(descTl)
  }

  destroy () {
    this.timelines.forEach(tl => {
      tl.kill()
    })
  }
}
