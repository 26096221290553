import * as THREE from 'three'
import Experience from './Experience.js'
import Scroll from '../scroll'
import Pointer from '../Pointer'
import vertexShader from './Shaders/japan/vertexShader.glsl'
import fragmentShader from './Shaders/japan/fragmentShader.glsl'
import bottomVertexShader from './Shaders/japanBottom/vertexShader.glsl'
import bottomFragmentShader from './Shaders/japanBottom/fragmentShader.glsl'

export default class Japan {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 68.75rem)')
    this.experience = new Experience()
    this.scroll = new Scroll()
    this.pointer = new Pointer()
    this.currentPointer = new THREE.Vector2(0, 0)
    this.prevPointer = new THREE.Vector2(0, 0)
    this.currentWave = 0
    this.resources = this.experience.resources
    this.scene = this.experience.scene
    this.time = this.experience.time
    this.debug = this.experience.debug
    this.config = this.experience.config
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.sizes = this.experience.sizes

    this.mobileOffset = 0

    this.init()
  }

  init () {
    if (this.mediaQuery.matches) {
      this.mobileOffset = this.config.height / 2.2
    } else {
      this.mobileOffset = 0
    }
    this.setSettings()
    this.setPlane()
  }

  setSettings () {
    this.settings = {
      uHeight: 600,
      smallNoiseSize: 3.5,
      addNoise: true,
      uTextureNoiseStrength: 0.14,
      uOpeningProgress: 0
    }
    this.palette = {
      color1: new THREE.Color('#e18700'),
      color2: new THREE.Color('#c29900'),
      color3: new THREE.Color('#884e00'),
      color4: new THREE.Color('#653304'),
      color5: new THREE.Color('#762a00')
    }
    this.paletteArray = [this.palette.color1, this.palette.color2, this.palette.color3, this.palette.color4, this.palette.color5]
  }

  setPlane () {
    const wrapper = document.querySelector('.wrapper')
    this.fullPageHeight = wrapper.clientHeight

    this.geometry = new THREE.PlaneGeometry(this.config.width * 2.5, this.config.width * 1.5589, 300, 300)
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uOpeningProgress: { value: this.settings.uOpeningProgress },
        uHeight: { value: this.settings.uHeight },
        uColor: { value: this.paletteArray },
        uTilt: { value: this.config.width / 2 },
        uTex: { value: this.baseTexture },
        uDisplacement: { value: null },
        smallNoiseSize: { value: this.settings.smallNoiseSize },
        addNoise: { value: this.settings.addNoise },
        uTextureNoiseStrength: { value: this.settings.uTextureNoiseStrength }
      }
    })

    this.bottomMaterial = new THREE.ShaderMaterial({
      vertexShader: bottomVertexShader,
      fragmentShader: bottomFragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uHeight: { value: this.settings.uHeight },
        uColor: { value: this.paletteArray },
        uTilt: { value: this.config.width / 2 },
        uTex: { value: this.baseTexture },
        uDisplacement: { value: null },
        smallNoiseSize: { value: this.settings.smallNoiseSize },
        addNoise: { value: this.settings.addNoise },
        uTextureNoiseStrength: { value: this.settings.uTextureNoiseStrength }
      }
    })
    this.plane = new THREE.Mesh(this.geometry, this.material)
    this.plane.name = 'japan'
    this.planeSettings = {
      x: 0.005,
      y: 0.01,
      z: 0.5
    }
    this.planeBottomSettings = {
      x: -0.33,
      y: 0.19,
      z: 0.13
    }

    this.plane.rotation.x = -Math.PI * 0.005
    this.plane.rotation.y = -Math.PI * 0.01
    this.plane.rotation.z = Math.PI + 0.5
    this.plane.position.z = 50
    this.scene.add(this.plane)
    if (!this.mediaQuery.matches) {
      this.planeBottom = new THREE.Mesh(this.geometry, this.bottomMaterial)
      this.planeBottom.name = 'japanBottom'
      this.planeBottom.rotation.x = Math.PI * this.planeBottomSettings.x
      this.planeBottom.rotation.y = -Math.PI * this.planeBottomSettings.y
      this.planeBottom.rotation.z = Math.PI + this.planeBottomSettings.z
      this.planeBottom.position.z = 30
      this.planeBottom.position.x = this.config.width / 6
      this.planeBottom.scale.set(1, 0.6, 0.7)
      this.scene.add(this.planeBottom)
    }
  }

  setPosition () {
    if (this.scroll) {
      this.currentScroll = this.scroll.value.current
      this.plane.position.y = this.currentScroll * 0.8 - this.config.height / 2.5 + this.mobileOffset
      if (!this.mediaQuery.matches && this.planeBottom) {
        this.planeBottom.position.y = this.currentScroll * 1.102 - (this.fullPageHeight * 1.075)
      }
    }
  }

  resize () {
    this.destroy()
    this.init()
  }

  update () {
    if (this.plane.geometry && this.plane.material) {
      this.setPosition()
      this.material.uniforms.uTime.value = this.time.elapsed

      if (window.openingProgress && window.openingProgress !== 0) {
        this.material.uniforms.uOpeningProgress.value = window.openingProgress
      }
    }

    if (this.planeBottom) {
      this.bottomMaterial.uniforms.uTime.value = this.time.elapsed
    }

    this.currentPointer.x = this.pointer.x - this.config.width / 2
    this.currentPointer.y = this.config.height / 2 - this.pointer.y
  }

  destroy () {
    this.scene.remove(this.plane)
    this.plane = null
    if (this.planeBottom) {
      this.scene.remove(this.planeBottom)
      this.planeBottom = null
    }
  }
}
