import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter {
  /**
     * Constructor
     */
  constructor () {
    super()

    // Viewport size
    this.viewport = {}
    this.$sizeViewport = document.createElement('div')
    this.$sizeViewport.style.width = '100vw'
    this.$sizeViewport.style.height = '100vh'
    this.$sizeViewport.style.position = 'absolute'
    this.$sizeViewport.style.top = 0
    this.$sizeViewport.style.left = 0
    this.$sizeViewport.style.pointerEvents = 'none'

    // パラメータ
    this.time = 0
    this.resizeParam = {
      prevSize: { w: 0, h: 0 },
      checkTime: 0,
      interval: 100 * 0.001
    }

    // Resize event
    // this.resize = this.resize.bind(this)
    // window.addEventListener('resize', this.resize)

    // this.resize()
  }

  /**
     * Resize
     */
  resize () {
    document.body.appendChild(this.$sizeViewport)
    this.viewport.width = this.$sizeViewport.offsetWidth
    this.viewport.height = this.$sizeViewport.offsetHeight
    document.body.removeChild(this.$sizeViewport)

    this.width = window.innerWidth
    this.height = window.innerHeight

    this.trigger('resize')
  }

  // 設定したinterval秒が経過したら、callbackを実行する
  // ただし、interval秒経過していない場合は、callbackを実行しない
  checkResize (time) {
    if (this.time - this.resizeParam.checkTime < this.resizeParam.interval) return false
    this.resizeParam.checkTime = time

    if (window.innerWidth !== this.resizeParam.prevSize.w || window.innerHeight !== this.resizeParam.prevSize.h) {
      this.resizeParam.prevSize.w = window.innerWidth
      this.resizeParam.prevSize.h = window.innerHeight
      return true
    }
  }

  update () {
    this.time = performance.now() * 0.0001
    if (this.checkResize(this.time)) this.resize()
  }
}
