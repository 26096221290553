import NormalizeWheel from 'normalize-wheel';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let scrollDetailInstance = null;
export default class Scroll {
    constructor() {
        if (scrollDetailInstance)
            return scrollDetailInstance;
        scrollDetailInstance = this;
        this.mediaQuery = window.matchMedia('(max-width: 68.75rem)');
        this.DOM = { main: document.querySelector('[data-scrollcontainer]') };
        this.wrapper = this.DOM.main.querySelector('[data-scroll]');
        this.init();
    }
    init() {
        if (this.mediaQuery.matches) {
            this.value = {
                current: 0,
                target: 0,
                last: 0,
                limit: 0,
                progress: 0,
                ease: 0.15,
                speed: 0,
                position: 0,
                isActive: true,
                isHorizontal: false,
                direction: 'down'
            };
        }
        else {
            this.value = {
                current: 0,
                target: 0,
                last: 0,
                limit: 0,
                progress: 0,
                ease: 0.04,
                speed: 0,
                position: 0,
                isActive: true,
                isHorizontal: false,
                direction: 'down'
            };
        }
        this.setStyle();
        this.addEventListeners();
        this.resize();
        this.setScrollTriggerProxy(this);
    }
    setStyle() {
        this.DOM.main.style.position = 'fixed';
        this.DOM.main.style.width = this.DOM.main.style.height = '100%';
        this.DOM.main.style.top = this.DOM.main.style.left = '0';
        this.DOM.main.style.overflow = 'hidden';
    }
    addEventListeners() {
        window.addEventListener('wheel', this.onWheel.bind(this));
        window.addEventListener('touchstart', this.onTouchDown.bind(this));
        window.addEventListener('touchmove', this.onTouchMove.bind(this));
        window.addEventListener('touchend', this.onTouchUp.bind(this));
        window.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowUp') {
                this.value.target -= 100;
            }
            else if (event.key === 'ArrowDown') {
                this.value.target += 100;
            }
        });
    }
    removeEventListeners() {
        window.removeEventListener('wheel', this.onWheel.bind(this));
        window.removeEventListener('touchstart', this.onTouchDown.bind(this));
        window.removeEventListener('touchmove', this.onTouchMove.bind(this));
        window.removeEventListener('touchend', this.onTouchUp.bind(this));
        window.removeEventListener('keydown', (event) => {
            if (event.key === 'ArrowUp') {
                this.value.target -= 100;
            }
            else if (event.key === 'ArrowDown') {
                this.value.target += 100;
            }
        });
    }
    onWheel(event) {
        if (this.value.isActive) {
            const normalizeWheel = NormalizeWheel(event);
            if (this.value.isHorizontal) {
                this.value.target += normalizeWheel.pixelY;
            }
            else {
                this.value.target += normalizeWheel.pixelY;
            }
        }
    }
    onTouchDown(event) {
        if (this.value.isActive) {
            this.isDown = true;
            this.value.position = this.value.current;
            this.start = event.touches ? event.touches[0].clientY : 0;
        }
    }
    onTouchMove(event) {
        if (this.value.isActive) {
            const y = event.touches ? event.touches[0].clientY : 0;
            const distance = (this.start - y) * 2;
            this.value.target = this.value.position + distance;
        }
    }
    onTouchUp(event) {
        if (this.value.isActive) {
            this.isDown = false;
        }
    }
    resize() {
        if (this.value.isHorizontal) {
            this.value.limit = this.wrapper.clientWidth - window.innerWidth;
            this.wrapper.style.overflowX = 'hidden';
        }
        else {
            this.value.limit = this.wrapper.clientHeight - window.innerHeight;
            this.wrapper.style.overflowY = 'hidden';
        }
    }
    update() {
        if (this.value.isHorizontal) {
            this.value.limit = this.wrapper.clientWidth - window.innerWidth;
        }
        else {
            this.value.limit = this.wrapper.clientHeight - window.innerHeight;
        }
        this.value.speed = Math.min(Math.abs(gsap.utils.clamp(0, this.value.limit, this.value.target) - gsap.utils.interpolate(this.value.current, this.value.target, this.value.ease)), 1000) / 1000;
        this.value.target = Math.round(gsap.utils.clamp(0, this.value.limit, this.value.target));
        this.value.current = Math.round(gsap.utils.interpolate(this.value.current, this.value.target, this.value.ease));
        if (this.value.current < 0.01) {
            this.value.current = 0;
        }
        if (this.value.isHorizontal) {
            this.wrapper.style.transform = `translate3d(${-this.value.current}px,0,0)`;
        }
        else {
            this.wrapper.style.transform = `translate3d(0, -${this.value.current}px, 0)`;
        }
        if (this.value.current < this.value.target) {
            this.value.direction = 'down';
        }
        else {
            this.value.direction = 'up';
        }
        ScrollTrigger.update();
    }
    setScrollTriggerProxy(scroller) {
        ScrollTrigger.scrollerProxy('[data-scroll]', {
            scrollTop(value) {
                if (arguments.length) {
                    scroller.value.current = value;
                }
                return scroller.value.current;
            },
            scrollLeft(value) {
                if (arguments.length) {
                    scroller.value.current = value;
                }
                return scroller.value.current;
            },
            getBoundingClientRect() {
                return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
            }
        });
        ScrollTrigger.defaults({ scroller: '[data-scroll]' });
        ScrollTrigger.addEventListener('refresh', () => {
            scroller.update();
        });
    }
    enable() {
        this.value.isActive = true;
    }
    disable() {
        this.value.isActive = false;
    }
    destroy() {
        this.removeEventListeners();
        ScrollTrigger.scrollerProxy(null);
        ScrollTrigger.clearScrollMemory();
        scrollDetailInstance = null;
    }
}
