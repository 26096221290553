import Experience from './Experience.js'
import Scroll from '../scroll'

export default class Header {
  constructor () {
    this.experience = new Experience()
    this.scroll = new Scroll()
    this.config = this.experience.config
    this.scene = this.experience.scene
    this.isHidden = false
    this.init()
  }

  init () {
    this.headerElement = document.querySelector('.js-header')
    this.headerNavList = this.headerElement.querySelector('.js-header__navList')
    this.headerLogo = this.headerElement.querySelector('.js-header__logo')
  }

  setHide () {
    this.headerElement.classList.add('is-hidden')
  }

  setShow () {
    this.headerElement.classList.remove('is-hidden')
  }

  update () {
    if (this.scroll.value.current < 300) {
      this.setShow()
      this.headerNavList.style.opacity = 1
      this.headerNavList.style.pointerEvents = 'auto'
      this.headerLogo.style.opacity = 1
      this.headerLogo.style.pointerEvents = 'auto'
      this.isHidden = false
    }
    if (!this.isHidden && this.scroll.value.direction === 'down') {
      this.setHide()
      this.isHidden = true
    }

    if (!(this.scroll.value.current < 100) && this.isHidden && this.scroll.value.direction === 'up' && this.scroll.value.speed > 0.3) {
      this.headerNavList.style.opacity = 0
      this.headerNavList.style.pointerEvents = 'none'
      this.headerLogo.style.opacity = 0
      this.headerLogo.style.pointerEvents = 'none'
      this.setShow()
      this.isHidden = false
    }
  }

  destroy () {
    this.headerElement.classList.remove('is-hidden')
  }
}
