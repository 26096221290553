import SplitText from '../SplitText'
import gsap from 'gsap'

export default class TextHover {
  constructor () {
    this.arrowTl = gsap.timeline()
    this.init()
    this.setHoverTrigger()
  }

  init () {
    this.headerAnchors = Array.from(document.querySelectorAll('.js-page-nav__item'))
    this.headerMenu = document.querySelector('.js-main-nav__item')
    this.menuClose = document.querySelector('.js-main-nav__close')
    this.headerAnchors.push(this.headerMenu)
    this.headerAnchors.push(this.menuClose)
    this.headerAnchorStore = this.headerAnchors.map((anchor, index) => {
      if (index === 5 || index === 6) {
        const text = anchor.querySelector('.js-rolling-text')
        const splitText = new SplitText(text, true, false)
        return {
          button: anchor,
          splitText
        }
      } else {
        const text = anchor.querySelector('.js-rolling-text')
        const splitText = new SplitText(text, true, false, anchor)
        return {
          button: anchor,
          splitText
        }
      }
    })

    this.linkButtons = Array.from(document.querySelectorAll('.js-footer__linkButton'))
    this.linkButtonStore = this.linkButtons.map((button, index) => {
      const text = button.querySelector('.js-rolling-text')
      const splitText = new SplitText(text)
      const arrows = button.querySelectorAll('.js-rolling-arrow>p')
      return {
        button,
        arrows,
        splitText
      }
    })
  }

  setHoverTrigger () {
    this.headerAnchorStore.forEach((anchor) => {
      anchor.button.addEventListener('mouseenter', () => {
        anchor.splitText.animate(0.02)
      })
      anchor.button.addEventListener('mouseleave', () => {
        anchor.splitText.reverseAnimate()
      })
    })

    this.linkButtonStore.forEach((linkButton) => {
      gsap.set(linkButton.arrows[1], {
        x: '-100%',
        y: '100%'
      })
    })
    this.linkButtonStore.forEach((linkButton, index) => {
      if (index === 3) {
        this.linkButtonDelay = 0.02
      } else {
        this.linkButtonDelay = 0.05
      }
      linkButton.button.addEventListener('mouseenter', () => {
        linkButton.splitText.animate(this.linkButtonDelay)
        this.startArrowAnimation(linkButton.arrows)
      })
      linkButton.button.addEventListener('mouseleave', () => {
        linkButton.splitText.reverseAnimate()
        this.reverseArrowAnimation(linkButton.arrows)
      })
    })
  }

  startArrowAnimation (arrows) {
    if (!this.arrowTl.isActive()) {
      this.arrowTl = gsap.timeline()
      this.arrowTl.to(arrows[0], {
        x: '100%',
        y: '-100%',
        duration: 1,
        ease: 'expo.out'
      }, 0)
        .to(arrows[1], {
          x: '0%',
          y: '0%',
          duration: 1,
          ease: 'expo.out'
        }, 0)
    }
  }

  reverseArrowAnimation () {
    this.arrowTl.reverse()
  }

  destroy () {
    this.headerAnchorStore.forEach((anchor) => {
      anchor.button.removeEventListener('mouseenter', () => {
        anchor.splitText.animate(0.05)
        this.startArrowAnimation(anchor.arrow)
      })
      anchor.button.removeEventListener('mouseleave', () => {
        anchor.splitText.reverseAnimate()
        this.reverseArrowAnimation(anchor.arrow)
      })
    })
    this.linkButtonStore.forEach((linkButton) => {
      linkButton.button.removeEventListener('mouseenter', () => {
        linkButton.splitText.animate()
        this.startArrowAnimation(linkButton.arrow)
      })
      linkButton.button.removeEventListener('mouseleave', () => {
        linkButton.splitText.reverseAnimate()
        this.reverseArrowAnimation(linkButton.arrow)
      })
    })

    this.arrowTl.kill()
  }
}
