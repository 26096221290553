import * as THREE from 'three'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import CustomEase from 'gsap/CustomEase'
import imagesLoaded from 'imagesloaded'
import Experience from './Experience.js'
import Scroll from '../scroll'
import Cursor from '../Cursor'
import { map } from './Utils/math.js'

import fragmentShader from './Shaders/waterflow/fragmentShader.glsl'
import vertexShader from './Shaders/waterflow/vertexShader.glsl'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(CustomEase)

export default class ImageDistortion {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.experience = new Experience()
    this.scroll = new Scroll()
    if (!this.mediaQuery.matches) {
      this.cursor = new Cursor()
    }
    this.resources = this.experience.resources
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.config = this.experience.config
    this.imageContainers = Array.from(document.querySelectorAll('.js-distortion-hover'))
    this.images = Array.from(document.querySelectorAll('.js-distortion-image'))
    this.homeWrapper = document.querySelector('.is-home')
    this.mouse = new THREE.Vector2()
    this.selectedProjectMeshs = []
    this.init()
  }

  init () {
    const preloadImage = new Promise((resolve, reject) => {
      imagesLoaded(this.images, { background: true }, resolve)
    })

    const allDone = [preloadImage]

    Promise.all(allDone).then(() => {
      this.addImages()
      this.setTimeline()
      this.setHover()
      this.setScrollTrigger()
      this.setRaycaster()
    })
  }

  reInit () {
    this.addImages()
    this.setTimeline()
    this.setHover()
    this.setScrollTrigger()
    this.setRaycaster()
  }

  addImages () {
    this.imageStore = this.imageContainers.map((img, index) => {
      const bounds = img.getBoundingClientRect()
      const geometry = new THREE.PlaneGeometry(bounds.width, bounds.height, 30, 30)
      const texture = new THREE.Texture(img.children[0].children[1])
      texture.needsUpdate = true

      this.settings = {
        uStrength: 0.06,
        uProgress: 0
      }

      const imagesmaterial = new THREE.ShaderMaterial({
        uniforms: {
          uTime: { value: 0 },
          uImage: { value: 0 },
          uTexture: { value: texture },
          uTextureChangeTrigger: { value: 0 },
          uResolution: { value: new THREE.Vector2(this.config.width, this.config.height) },
          uRealSize: { value: new THREE.Vector2(bounds.width, bounds.height) },
          uPlaneSizes: { value: new THREE.Vector2(bounds.width, bounds.height) },
          uStrength: { value: this.settings.uStrength },
          uProgress: { value: this.settings.uProgress },
          uOpacity: { value: 1 },
          uCorners: { value: new THREE.Vector4(0.0, 0.0, 0.0, 0.0) },
          uYScale: { value: 0 },
          uYBottom: { value: 0 },
          uMouse: { value: new THREE.Vector2(0, 0) },
          uLightStrength: { value: 1 }
        },
        vertexShader,
        fragmentShader,
        transparent: true
      })

      const mesh = new THREE.Mesh(geometry, imagesmaterial)
      mesh.name = 'selectedProjectImage'
      this.scene.add(mesh)
      this.selectedProjectMeshs.push(mesh)

      return {
        img,
        texture,
        isColored: false,
        mesh,
        top: this.scroll.value.current + bounds.top,
        left: bounds.left,
        width: bounds.width,
        height: bounds.height,
        tl: this.setTimeline(mesh)
      }
    })
  }

  setTimeline (o) {
    if (o) {
      const distortionTl = gsap.timeline()
      return distortionTl.to(o.material.uniforms.uCorners.value, {
        x: -1,
        duration: 1
      })
        .to(o.material.uniforms.uCorners.value, {
          y: -1,
          duration: 1
        }, 0.4)
        .to(o.material.uniforms.uCorners.value, {
          z: -1,
          duration: 1
        }, 0.8)
        .to(o.material.uniforms.uCorners.value, {
          w: -1,
          duration: 1
        }, 1.2)
    }
  }

  setHover () {
    this.hoverTargets = Array.from(document.querySelectorAll('.js-distortion-hover'))
  }

  setScrollTrigger () {
    this.imageStore.forEach((o, i) => {
      const tl = gsap.timeline()
      ScrollTrigger.create({
        trigger: o.img,
        start: 'top center+=30%',
        onEnter: () => {
          tl.to(o.mesh.material.uniforms.uProgress, {
            value: 1,
            duration: 2.5,
            ease: CustomEase.create('custom', 'M0,0,C0.008,0.024,0.003,0.042,0.096,0.076,0.266,0.138,0.474,0.628,0.586,0.758,0.756,0.956,0.818,1.001,1,1')
          })
            .to(o.mesh.material.uniforms.uYScale, {
              value: 1,
              delay: 0.2,
              duration: 1,
              ease: 'power2.out'
            }, 0)
        },
        onLeave: () => {
        },
        markers: false
      })
    })
  }

  setRaycaster () {
    this.raycaster = new THREE.Raycaster()
    this.pointer = new THREE.Vector2()

    this.homeWrapper.addEventListener('pointermove', this._onMouseMove.bind(this), false)
    this.imageStore.forEach((o, i) => {
      o.img.addEventListener('pointerenter', () => {
        gsap.to(o.mesh.material.uniforms.uLightStrength, {
          value: 1,
          duration: 1,
          ease: 'power2.out'
        })
      })
      o.img.addEventListener('pointerleave', () => {
        gsap.to(o.mesh.material.uniforms.uLightStrength, {
          value: 0,
          duration: 1,
          ease: 'power2.out'
        })
      })
    })
  }

  _onMouseMove (event) {
    // マウスの位置を取得及び正規化
    this.mouse.x = (event.clientX / this.config.width) * 2 - 1
    this.mouse.y = -(event.clientY / this.config.height) * 2 + 1
  }

  setPosition () {
    this.imageStore.forEach((o, index) => {
      o.mesh.position.x = o.left - this.config.width / 2 + o.width / 2
      o.mesh.position.y = this.scroll.value.current - o.top + this.config.height / 2 - o.height / 2
      o.mesh.position.z = gsap.utils.interpolate(o.mesh.position.z, -this.scroll.value.speed * 50, 0.03)
      o.mesh.rotation.x = gsap.utils.interpolate(o.mesh.rotation.x, -(Math.PI / 40) * this.scroll.value.speed, 0.1)
      o.mesh.material.uniforms.uYBottom.value = o.mesh.position.y - o.mesh.geometry.parameters.height / 2
    })
  }

  resize () {
    if (this.imageStore) {
      this.imageStore.forEach(o => {
        this.scene.remove(o.mesh)
      })
    }
    this.imageStore = []
    this.selectedProjectMeshs = []
    this.reInit()
  }

  update (elapsed) {
    if (this.imageStore && !window.onAnchorScrolling) {
      this.setPosition()
      this.imageStore.forEach((o, index) => {
        o.mesh.material.uniforms.uTime.value = elapsed
        // o.mesh.material.uniforms.uStrength.value = this.settings.uStrength
        o.tl.progress(map(this.scroll.value.speed, 0.12, 1, 0, 0.3))
      })
    }

    if (this.raycaster) {
      this.raycaster.setFromCamera(this.mouse, this.camera.instance)
      const intersects = this.raycaster.intersectObjects(this.selectedProjectMeshs)
      if (intersects.length > 0) {
        if (this.cursor && this.cursor.cursorConfig.scale.current === 0) {
          this.cursor.enter()
        }
        const intersect = intersects[0]
        const object = intersect.object
        if (object.material.uniforms.uMouse) {
          object.material.uniforms.uMouse.value.x = gsap.utils.interpolate(object.material.uniforms.uMouse.value.x, intersect.uv.x, 0.04)
          object.material.uniforms.uMouse.value.y = gsap.utils.interpolate(object.material.uniforms.uMouse.value.y, intersect.uv.y, 0.04)
          const rotateRateX = (intersect.uv.y - 0.5) * 2
          const rotateRateY = (intersect.uv.x - 0.5) * 2
          object.rotation.x = gsap.utils.interpolate(object.rotation.x, -rotateRateX * Math.PI / 180 * 2.2, 0.06)
          object.rotation.y = gsap.utils.interpolate(object.rotation.y, rotateRateY * Math.PI / 180 * 2.2, 0.06)
        }
      } else if (this.cursor && this.cursor.cursorConfig.scale.current > 0) {
        this.cursor.leave()
      }
    }
  }

  destroy () {
    this.imageStore = []
    this.selectedProjectMeshs = []
  }
}
