import SplitText from '../SplitText'
import gsap from 'gsap'

export default class TextHover {
  constructor () {
    this.arrowTl = gsap.timeline()
    this.linkArrowTl = gsap.timeline()
    this.init()
    this.setHoverTrigger()
  }

  init () {
    this.container = document.querySelector('.js-detail__backButton')
    this.text = document.querySelector('.js-rolling-text')
    this.arrows = Array.from(document.querySelectorAll('.js-detail__backButton__arrow>p'))
    this.splitText = new SplitText(this.text)

    this.linkContainer = document.querySelector('.js-detail-firstView__sign')
    this.linkText = document.querySelector('.js-project-detail-firstView__toProjectText')
    this.linkSplitText = new SplitText(this.linkText)
    this.linkArrows = Array.from(document.querySelectorAll('.js-project-detail-firstView__toProjectArrow>p'))
  }

  setHoverTrigger () {
    // back
    this.container.addEventListener('mouseenter', () => {
      this.splitText.animate(0.05)
      this.startArrowAnimation()
    })
    this.container.addEventListener('mouseleave', () => {
      this.splitText.reverseAnimate()
      this.reverseArrowAnimation()
    })

    // link
    gsap.set(this.linkArrows[1], {
      x: '-100%',
      y: '100%'
    })
    this.linkContainer.addEventListener('mouseenter', () => {
      this.linkSplitText.animate(0.02)
      this.startLinkArrowAnimation()
    })
    this.linkContainer.addEventListener('mouseleave', () => {
      this.linkSplitText.reverseAnimate()
      this.reverseLinkArrowAnimation()
    })
  }

  startArrowAnimation () {
    if (!this.arrowTl.isActive()) {
      this.arrowTl = gsap.timeline()
      this.arrowTl.to(this.arrows[0], {
        x: '-100%',
        duration: 1,
        ease: 'expo.out'
      }, 0)
        .to(this.arrows[1], {
          x: '-100%',
          duration: 1,
          ease: 'expo.out'
        }, 0)
    }
  }

  startLinkArrowAnimation () {
    if (!this.linkArrowTl.isActive()) {
      this.linkArrowTl = gsap.timeline()
      this.linkArrowTl.to(this.linkArrows[0], {
        x: '100%',
        y: '-100%',
        duration: 1,
        ease: 'expo.out'
      }, 0)
        .to(this.linkArrows[1], {
          x: '0%',
          y: '0%',
          duration: 1,
          ease: 'expo.out'
        }, 0)
    }
  }

  reverseArrowAnimation () {
    this.arrowTl.reverse()
  }

  reverseLinkArrowAnimation () {
    this.linkArrowTl.reverse()
  }

  destroy () {
    this.container.removeEventListener('mouseenter', () => {
      this.splitText.animate()
      this.startArrowAnimation()
    })
    this.container.removeEventListener('mouseleave', () => {
      this.splitText.reverseAnimate()
      this.reverseArrowAnimation()
    })

    this.linkContainer.removeEventListener('mouseenter', () => {
      this.linkSplitText.animate()
      this.startLinkArrowAnimation()
    })

    this.linkContainer.removeEventListener('mouseleave', () => {
      this.linkSplitText.reverseAnimate()
      this.reverseLinkArrowAnimation()
    })

    this.arrowTl.kill()
    this.linkArrowTl.kill()
  }
}
