import gsap from 'gsap'
import Experience from './Experience.js'
import Scroll from '../scroll'

window.onAnchorScrolling = false

export default class AnchorLink {
  constructor () {
    this.experience = new Experience()
    this.scroll = new Scroll()
    this.config = this.experience.config
    this.scene = this.experience.scene
    this.links = document.querySelectorAll('.js-page-nav__link')

    this.horizontasScrollWrapper = document.querySelector('.js-horizontal-scroll')
    this.horizontalScrollLength = this.horizontasScrollWrapper.offsetWidth
    this.culculateDistance()
    this.createAnchors()
  }

  culculateDistance () {
    this.sectionHeights = {}
    this.sectionHeights.home = document.getElementById('home').offsetHeight
    this.sectionHeights.message = document.getElementById('message').offsetHeight
    this.sectionHeights.projects = document.getElementById('projects').offsetHeight
    this.sectionHeights.archives = document.getElementById('archives').offsetHeight + this.horizontalScrollLength
    this.sectionHeights.inspirations = document.getElementById('inspirations').offsetHeight + document.querySelector('.js-inspirations-images').offsetHeight
    this.sectionHeights.principles = document.getElementById('principles').offsetHeight + (document.querySelector('.js-principles__cardsContainer').offsetHeight * 6)
    this.sectionHeights.lastquote = document.getElementById('lastquote').offsetHeight
    this.sectionHeights.lastmessage = document.getElementById('lastmessage').offsetHeight
  }

  createAnchors () {
    this.links.forEach((link, index) => {
      link.addEventListener('click', (e) => {
        window.onAnchorScrolling = true
        e.preventDefault()
        gsap.to(this.scroll.value, {
          duration: 3,
          ease: 'expo.inOut',
          target: () => {
            switch (index) {
              case 0:
                return 0
              case 1:
                return this.sectionHeights.home + this.sectionHeights.message
              case 2:
                return this.sectionHeights.home + this.sectionHeights.message + this.sectionHeights.projects + this.sectionHeights.archives
              case 3:
                return this.sectionHeights.home + this.sectionHeights.message + this.sectionHeights.projects + this.sectionHeights.archives + this.sectionHeights.inspirations
              case 4:
                return this.sectionHeights.home + this.sectionHeights.message + this.sectionHeights.projects + this.sectionHeights.archives + this.sectionHeights.inspirations + this.sectionHeights.principles + this.sectionHeights.lastquote + this.sectionHeights.lastmessage
            }
          },
          onUpdate: () => {
            if (!window.onAnchorScrolling) {
              window.onAnchorScrolling = true
            }
          },
          onComplete: () => {
            window.onAnchorScrolling = false
          }
        })
      })
    })
  }

  resize () {
    this.culculateDistance()
  }
}
