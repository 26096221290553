import * as THREE from 'three'
import gsap from 'gsap'
import imagesLoaded from 'imagesloaded'
import Experience from './Experience.js'
import Scroll from '../scrollDetail'
import Cursor from '../Cursor'
import { map } from './Utils/math.js'

import fragmentShader from './Shaders/distortionImage/fragmentShader.glsl'
import vertexShader from './Shaders/distortionImage/vertexShader.glsl'
import fragmentShaderNormal from './Shaders/distortionImageNormal/fragmentShader.glsl'
import vertexShaderNormal from './Shaders/distortionImageNormal/vertexShader.glsl'

export default class ImageDistortion {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.experience = new Experience()
    this.scroll = new Scroll()
    if (!this.mediaQuery.matches) {
      this.cursor = new Cursor()
    }
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.config = this.experience.config
    this.hoverTarget = document.querySelector('.js-project-detail-lastView__hover-target')
    this.images = Array.from(document.querySelectorAll('.js-detail-imageDistortion'))
    this.mouse = new THREE.Vector2()
    this.lastMesh = []

    // プリロードの設定
    const preloadImage = new Promise((resolve, reject) => {
      imagesLoaded(this.images, { background: true }, resolve)
    })

    const allDone = [preloadImage]
    this.addImages()

    Promise.all(allDone).then(() => {
      this.setTimeline()
      this.setHover()
      this.setRayCaster()
    })
  }

  reInit () {
    this.addImages()
    this.setTimeline()
    this.setHover()
    this.setRayCaster()
  }

  addImages () {
    this.imageStore = this.images.map((img, index) => {
      const bounds = img.getBoundingClientRect()
      const geometry = new THREE.PlaneGeometry(bounds.width, bounds.height, 30, 30)
      const texture = new THREE.Texture(img.children[1])
      let mesh = null
      texture.needsUpdate = true
      if (index === 9) {
        this.settings = {
          uStrength: 0.15,
          uProgress: 0
        }

        const imagesmaterialLast = new THREE.ShaderMaterial({
          uniforms: {
            uTime: { value: 0 },
            uImage: { value: 0 },
            uTexture: { value: texture },
            uResolution: { value: new THREE.Vector2(this.config.width, this.config.height) },
            uRealSize: { value: new THREE.Vector2(bounds.width, bounds.height) },
            uPlaneSizes: { value: new THREE.Vector2(bounds.width, bounds.height) },
            uStrength: { value: this.settings.uStrength },
            uProgress: { value: this.settings.uProgress },
            uOpacity: { value: 1 },
            uCorners: { value: new THREE.Vector4(0.0, 0.0, 0.0, 0.0) },
            uTransparency: { value: 1.0 },
            uMouse: { value: new THREE.Vector2(0, 0) },
            uLightStrength: { value: 0 }
          },
          vertexShader,
          fragmentShader,
          transparent: true
        })
        mesh = new THREE.Mesh(geometry, imagesmaterialLast)
        mesh.name = 'last'
      } else {
        this.settings = {
          uStrength: 0.01,
          uProgress: 0
        }
        const imagesmaterial = new THREE.ShaderMaterial({
          uniforms: {
            uTime: { value: 0 },
            uImage: { value: 0 },
            uTextureChangeTrigger: { value: 0 },
            uTexture: { value: texture },
            uTextureColored: { value: 0 },
            uResolution: { value: new THREE.Vector2(this.config.width, this.config.height) },
            uRealSize: { value: new THREE.Vector2(bounds.width, bounds.height) },
            uPlaneSizes: { value: new THREE.Vector2(bounds.width, bounds.height) },
            uStrength: { value: this.settings.uStrength },
            uProgress: { value: this.settings.uProgress },
            uOpacity: { value: 1 },
            uCorners: { value: new THREE.Vector4(0.0, 0.0, 0.0, 0.0) },
            uTransparency: { value: 1.0 },
            uMouse: { value: new THREE.Vector2(0, 0) }
          },
          vertexShader: vertexShaderNormal,
          fragmentShader: fragmentShaderNormal,
          transparent: true
        })
        mesh = new THREE.Mesh(geometry, imagesmaterial)
        mesh.name = 'gallery'
      }
      if (index === 0) {
        mesh.position.x = -(this.scroll.value.current - this.scroll.value.current + bounds.left + this.config.width / 2 - bounds.width / 2)
        mesh.position.y = -(top - this.config.height / 2 + bounds.height / 2)
        mesh.position.z = -this.scroll.value.speed * 15
        mesh.name = 'first'
      }
      this.scene.add(mesh)

      if (index === 9) {
        if (this.lastMesh.length > 0) {
          this.lastMesh[0] = mesh
        } else {
          this.lastMesh.push(mesh)
        }
      }

      return {
        img,
        mesh,
        top: bounds.top,
        left: this.scroll.value.current + bounds.left,
        width: bounds.width,
        height: bounds.height,
        tl: this.setTimeline(mesh)
      }
    })
  }

  setTimeline (o) {
    if (o) {
      const distortionTl = gsap.timeline()
      return distortionTl.to(o.material.uniforms.uCorners.value, {
        x: -1,
        duration: 1
      })
        .to(o.material.uniforms.uCorners.value, {
          y: -1,
          duration: 1
        }, 0.4)
        .to(o.material.uniforms.uCorners.value, {
          z: -1,
          duration: 1
        }, 0.8)
        .to(o.material.uniforms.uCorners.value, {
          w: -1,
          duration: 1
        }, 1.2)
    }
  }

  setHover () {
    const tl = gsap.timeline()
    if (!this.mediaQuery.matches) {
      this.hoverTarget.addEventListener('mouseenter', () => {
        if (tl.paused()) {
          tl.restart()
          return
        }
        tl.to(this.imageStore[9].mesh.material.uniforms.uProgress, {
          value: 0.2,
          duration: 1.5,
          ease: 'none'
        })
        gsap.to(this.imageStore[9].mesh.material.uniforms.uLightStrength, {
          value: 1,
          duration: 1,
          ease: 'power2.out'
        })
      })
      this.hoverTarget.addEventListener('mouseleave', () => {
        gsap.to(this.imageStore[9].mesh.material.uniforms.uLightStrength, {
          value: 0,
          duration: 1,
          ease: 'power2.out'
        })
      })
    }
  }

  setRayCaster () {
    this.raycaster = new THREE.Raycaster()
    this.pointer = new THREE.Vector2()

    window.addEventListener('pointermove', this._onMouseMove.bind(this), false)
  }

  destroyRayCaster () {
    window.removeEventListener('pointermove', this._onMouseMove.bind(this), false)
  }

  _onMouseMove (event) {
    // マウスの位置を取得及び正規化
    this.mouse.x = (event.clientX / this.config.width) * 2 - 1
    this.mouse.y = -(event.clientY / this.config.height) * 2 + 1
  }

  setPosition () {
    this.imageStore.forEach((o, index) => {
      if (index === 0) {
        if (!this.experience.isLoading) {
          o.mesh.position.x = -(this.scroll.value.current - o.left + this.config.width / 2 - o.width / 2)
          o.mesh.position.y = -(o.top - this.config.height / 2 + o.height / 2)
          o.mesh.position.z = -this.scroll.value.speed * 15
        }
      } else {
        o.mesh.position.x = -(this.scroll.value.current - o.left + this.config.width / 2 - o.width / 2)
        o.mesh.position.y = -(o.top - this.config.height / 2 + o.height / 2)
        o.mesh.position.z = -this.scroll.value.speed * 15
      }
    })
  }

  resize () {
    if (this.imageStore) {
      this.imageStore.forEach(o => {
        this.scene.remove(o.mesh)
        // o.tl.kill()
      })
    }
    this.destroyRayCaster()
    this.imageStore = []
    this.reInit()
  }

  update (elapsed) {
    if (this.imageStore) {
      this.setPosition()
      this.imageStore.forEach(o => {
        o.mesh.material.uniforms.uTime.value = elapsed
        o.mesh.material.uniforms.uStrength.value = this.settings.uStrength
        o.tl.progress(map(this.scroll.value.speed, 0, 1, 0, 0.1))
      })
    }

    if (this.raycaster) {
      this.raycaster.setFromCamera(this.mouse, this.camera.instance)
      const intersects = this.raycaster.intersectObjects(this.lastMesh)
      if (intersects.length > 0) {
        if (this.cursor && this.cursor.cursorConfig.scale.current === 0) {
          this.cursor.enter()
        }
        const intersect = intersects[0]
        const object = intersect.object
        if (object.material.uniforms.uMouse) {
          object.material.uniforms.uMouse.value.x = gsap.utils.interpolate(object.material.uniforms.uMouse.value.x, intersect.uv.x, 0.04)
          object.material.uniforms.uMouse.value.y = gsap.utils.interpolate(object.material.uniforms.uMouse.value.y, intersect.uv.y, 0.04)
        }
      } else if (this.cursor && this.cursor.cursorConfig.scale.current > 0) {
        this.cursor.leave()
      }
    }
  }

  destroy () {
    this.destroyRayCaster()
    this.imageStore = []
    this.lastMesh = []
  }
}
