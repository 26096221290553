import gsap from 'gsap';
export default class SplitText {
    constructor(target, isDuplicateTarget = true, isVertical = false, parent) {
        this.DOM = { main: target };
        this.content = this.DOM.main.textContent;
        this.chars = this.content.split('');
        this.spans = [];
        this.tl = gsap.timeline();
        this.verticalTl = gsap.timeline();
        this.init(isDuplicateTarget, isVertical, parent);
    }
    init(isDuplicateTarget, isVertical, parent) {
        this.DOM.main.textContent = '';
        this.chars.forEach((char, i) => {
            const span = document.createElement('span');
            span.textContent = char;
            if (char === ' ') {
                if (isVertical) {
                    span.style.height = '0.2em';
                    span.style.width = '1em';
                }
                else {
                    span.style.height = '1em';
                    span.style.width = '0.2em';
                }
            }
            if (char === '・') {
                span.classList.add('nakaguro');
            }
            span.style.display = 'inline-block';
            span.style.opacity = '1';
            span.style.transform = 'translate3d(0,0%,0)';
            span.setAttribute('aria-hidden', 'true');
            this.DOM.main.appendChild(span);
            this.spans.push(span);
        });
        this.contentForScreenReader = document.createElement('div');
        this.contentForScreenReader.classList.add('visually-hidden');
        this.contentForScreenReader.textContent = this.content;
        this.DOM.main.appendChild(this.contentForScreenReader);
        if (isDuplicateTarget) {
            this.duplicatedElement = this.DOM.main.cloneNode(true);
            const parentStyle = getComputedStyle(this.DOM.main.parentElement);
            if (parent) {
                const style = getComputedStyle(parent);
                if (style.position === 'absolute') {
                    parent.style.overflow = 'hidden';
                }
                else {
                    parent.style.position = 'relative';
                    parent.style.overflow = 'hidden';
                }
            }
            else if (parentStyle.position === 'absolute') {
                this.DOM.main.parentElement.style.overflow = 'hidden';
            }
            else {
                this.DOM.main.parentElement.style.position = 'relative';
                this.DOM.main.parentElement.style.overflow = 'hidden';
            }
            this.duplicatedElement.style.position = 'absolute';
            this.duplicatedElement.style.top = '100%';
            this.duplicatedElement.style.transform = 'translate3d(0,0,0)';
            this.duplicatedElement.style.left = '0';
            this.duplicatedElement.style.opacity = '1';
            this.DOM.main.parentElement.appendChild(this.duplicatedElement);
            this.spansSub = Array.from(this.duplicatedElement.querySelectorAll('span'));
        }
    }
    animate(textSpeed) {
        if (!this.tl.isActive()) {
            this.tl = gsap.timeline();
            this.spans.forEach((span, i) => {
                this.tl.to(span, {
                    duration: 1,
                    opacity: 0,
                    rotationX: 45,
                    y: '-100%',
                    delay: i * textSpeed,
                    ease: 'expo.out',
                }, 0);
            });
            this.spansSub.forEach((span, i) => {
                this.tl.fromTo(span, {
                    opacity: 0,
                    rotationX: 45,
                }, {
                    duration: 1,
                    opacity: 1,
                    rotationX: 0,
                    y: '-100%',
                    delay: i * textSpeed,
                    ease: 'expo.out',
                }, 0);
            });
        }
    }
    reverseAnimate() {
        this.tl.reverse();
    }
    setAnimateVerticalText(intensity = 1) {
        this.spans.forEach((span, i) => {
            gsap.set(span, {
                opacity: 0,
                y: `${200 * intensity}%`
            });
        });
    }
    resetAnimateVerticalText() {
        this.spans.forEach((span, i) => {
            gsap.set(span, {
                opacity: 1,
                y: `0%`
            });
        });
    }
    animateVerticalText(textSpeed = 0.08, index = 1) {
        if (!this.verticalTl.isActive()) {
            this.verticalTl = gsap.timeline();
            this.spans.forEach((span, i) => {
                this.verticalTl.to(span, {
                    duration: 0.8,
                    y: '0%',
                    delay: i * textSpeed + index * 0.1,
                    ease: 'power1.out',
                }, 0)
                    .to(span, {
                    duration: 0.8,
                    opacity: 1,
                    delay: i * textSpeed + index * 0.1,
                    ease: 'none',
                }, 0);
            });
        }
    }
    setAnimateVerticalTextEnglish(intensity = 1) {
        this.spans.forEach((span, i) => {
            gsap.set(span, {
                opacity: 0,
                y: `0.8em`
            });
        });
    }
    animateVerticalTextEnglish(textSpeed, delay, index) {
        if (!this.verticalTl || !this.verticalTl.isActive()) {
            this.verticalTl = gsap.timeline();
            this.spans.forEach((span, i) => {
                this.verticalTl.to(span, {
                    duration: textSpeed,
                    y: '0em',
                    delay: i * delay + index * 0.1,
                    ease: 'power3.out',
                }, 0)
                    .to(span, {
                    duration: textSpeed,
                    opacity: 1,
                    delay: i * delay + index * 0.1,
                    ease: 'none',
                }, 0);
            });
        }
    }
    resetVerticalText() {
        this.verticalTl.pause(0);
    }
    replayVerticalText() {
        this.verticalTl.play();
    }
    reverseVerticalText() {
        this.verticalTl.reverse();
    }
}
