import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Experience from './Experience.js'
import SplitText from '../SplitText'
gsap.registerPlugin(ScrollTrigger)

export default class Text {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.experience = new Experience()
    this.timelines = []
    this.createSectionTitleAnimation()
    this.createMessageTitleAnimation()
    this.createSelectedProjectsAnimation()
    this.createLastMessageAnimation()
  }

  createSectionTitleAnimation () {
    const titleWrappers = Array.from(document.querySelectorAll('.js-section-titles'))
    const titleCaptions = Array.from(document.querySelectorAll('.js-title-caption'))
    const titleTexts = Array.from(document.querySelectorAll('.js-section-title>.line>p'))
    this.duration = 0.8
    this.fadeInDuration = 1.2
    this.titleEase = 'power3.out'
    this.fadeInEase = 'none'

    titleWrappers.forEach((titleWrapper, index) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          scroller: '[data-scroll]',
          trigger: titleWrapper,
          start: 'top bottom-=10%',
          toggleActions: 'play none none reverse',
          markers: false
        }
      })
      tl.from(titleCaptions[index], {
        y: '50%',
        duration: this.duration,
        ease: this.titleEase
      }, 0)
        .from(titleCaptions[index], {
          opacity: 0,
          duration: this.fadeInDuration,
          ease: this.fadeInEase
        }, 0)
        .from(titleTexts[index * 2], {
          y: '50%',
          delay: 0.2,
          duration: this.duration,
          ease: this.titleEase
        }, 0)
        .from(titleTexts[index * 2], {
          delay: 0.2,
          duration: this.fadeInDuration,
          opacity: 0,
          ease: this.fadeInEase
        }, 0)
        .from(titleTexts[index * 2 + 1], {
          y: '50%',
          duration: this.duration,
          delay: 0.4,
          ease: this.titleEase
        }, 0)
        .from(titleTexts[index * 2 + 1], {
          duration: this.fadeInDuration,
          delay: 0.4,
          opacity: 0,
          ease: this.fadeInEase
        }, 0)

      this.timelines.push(tl)
    })
  }

  createMessageTitleAnimation () {
    // 欧文
    const messageWrapper = document.querySelector('.js-message-container')
    const messages = document.querySelectorAll('.js-message__description-items>.line>p')
    const messageDuration = 0.8
    const messageFadeInDuration = 1.0

    if (this.mediaQuery.matches) {
      this.messageTl = gsap.timeline()
      messages.forEach((message, index) => {
        this.messageTl.from(message, {
          y: '100%',
          duration: messageDuration,
          delay: index * 0.2,
          ease: 'power3.out'
        }, 0)
          .from(message, {
            duration: messageFadeInDuration,
            delay: index * 0.2,
            opacity: 0,
            ease: this.fadeInEase
          }, 0)
      })
    } else {
      this.messageTl = gsap.timeline({
        scrollTrigger: {
          scroller: '[data-scroll]',
          trigger: messageWrapper,
          start: 'top bottom-=5%',
          toggleActions: 'play none none reverse',
          markers: false
        }
      })
      messages.forEach((message, index) => {
        this.messageTl.from(message, {
          y: '100%',
          duration: messageDuration,
          delay: index * 0.2,
          ease: 'power3.out'
        }, 0)
          .from(message, {
            duration: messageFadeInDuration,
            delay: index * 0.2,
            opacity: 0,
            ease: this.fadeInEase
          }, 0)
      })
    }
    this.timelines.push(this.messageTl)
    // 和文
    const verticalMessageContainer = document.querySelector('.js-vertical-descriptions-container')
    const verticalMessageTexts = Array.from(document.querySelectorAll('.js-message__vertical-description-item>.line>p'))

    this.splitMessageStore = verticalMessageTexts.map((text, index) => {
      const splitText = new SplitText(text, false, true)
      splitText.setAnimateVerticalText()
      return {
        splitText
      }
    }
    )

    const verticalMessageTl = gsap.timeline({
      scrollTrigger: {
        scroller: '[data-scroll]',
        trigger: verticalMessageContainer,
        start: 'top center+=35%',
        toggleActions: 'play none none reverse',
        markers: false,
        onEnter: () => {
          this.splitMessageStore.forEach((splitMessage, index) => {
            splitMessage.splitText.animateVerticalText(0.03, index)
          })
        }
      }
    })

    this.timelines.push(verticalMessageTl)
  }

  createSelectedProjectsAnimation () {
    this.selectedItems = Array.from(document.querySelectorAll('.js-selected-projects__item'))
    this.borderDuration = 1.5
    this.selectedTitleDuration = 0.8
    this.selectedTitleFadeInDuration = 1.0

    this.selectedItems.forEach((item, index) => {
      // const titletexts = Array.from(title.querySelectorAll('.line>p'))
      const border = item.querySelector('.js-selected-projects__border')
      const itemTl = gsap.timeline({
        scrollTrigger: {
          scroller: '[data-scroll]',
          trigger: item,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none none',
          markers: false
        }
      })
      itemTl.to(border, {
        scaleX: 1,
        duration: this.borderDuration,
        ease: 'expo.out'
      }, 0)
      this.timelines.push(itemTl)
    })
  }

  createLastMessageAnimation () {
    this.lastMessageContainer = document.querySelector('.js-lastMessage__wrapper')
    this.lastMessageTexts = Array.from(this.lastMessageContainer.querySelectorAll('.js-lastMessage__text'))

    const lastMessageTl = gsap.timeline({
      scrollTrigger: {
        scroller: '[data-scroll]',
        trigger: this.lastMessageContainer,
        start: 'top bottom',
        toggleActions: 'play none none reverse',
        markers: false
      }
    })
    this.timelines.push(lastMessageTl)

    this.lastMessageTexts.forEach((text, index) => {
      lastMessageTl.from(text, {
        y: '100%',
        duration: 0.8,
        delay: index * 0.2,
        ease: 'power3.out'
      }, 0)
        .from(text, {
          duration: 1.0,
          delay: index * 0.2,
          opacity: 0,
          ease: this.fadeInEase
        }, 0)

      this.timelines.push(lastMessageTl)
    })
  }

  destroy () {
    this.timelines.forEach(tl => {
      tl.kill()
    })
  }
}
