export class Tween1 {
    constructor(x, alpha) {
        this.x = x;
        this.alpha = alpha;
        this.velocity = 0;
    }
    lerp(a, b, n) {
        return (1 - n) * a + n * b;
    }
    update(target, gamma) {
        const beta = Math.exp(-this.alpha * gamma);
        const newX = this.lerp(target, this.x, beta);
        this.velocity = newX - this.x;
        this.x = newX;
    }
    reset() {
        this.x = 0;
        this.velocity = 0;
    }
}
export class Tween2 {
    constructor(positionX, positionY, alpha) {
        this.position = { x: positionX, y: positionY };
        this.velocity = { x: 0, y: 0 };
        this.alpha = alpha;
        this.direction = 0;
    }
    lerp(a, b, n) {
        return (1 - n) * a + n * b;
    }
    update(target, gamma) {
        const beta = Math.exp(-this.alpha * gamma);
        const newX = this.lerp(target.x, this.position.x, beta);
        const newY = this.lerp(target.y, this.position.y, beta);
        this.velocity.x = newX - this.position.x;
        this.velocity.y = newY - this.position.y;
        this.position.x = newX;
        this.position.y = newY;
    }
    calcDirection(target) {
        this.direction = Math.atan2(this.position.y - target.y, this.position.x - target.x);
    }
    reset() {
        this.position.x = 0;
        this.position.y = 0;
        this.velocity.x = 0;
        this.velocity.y = 0;
        this.direction = 0;
    }
}
