import * as THREE from 'three'
import Time from './Utils/Time.js'
import Sizes from './Utils/Sizes.js'
import Stats from './Utils/Stats.js'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import Resources from './Resources.js'
import Renderer from './Renderer.js'
import Camera from './Camera.js'
import HorizontalScroll from './HorizontalScroll.js'
import ImageScroll from './ImageScroll.js'
import CardFolding from './CardFolding.js'
import ImageDistortion from './ImageDistortion.js'
import Text from './Text.js'
import AnchorLink from './AnchorLink.js'
import Header from './Header.js'
import Menu from './Menu.js'
import TextHover from './TextHover.js'

import assets from './assets.js'

gsap.registerPlugin(ScrollTrigger)

export default class Experience {
  static instance

  constructor (_options = {}) {
    if (Experience.instance) {
      return Experience.instance
    }
    Experience.instance = this

    // Options
    this.targetElement = _options.targetElement

    if (!this.targetElement) {
      console.warn('Missing \'targetElement\' property')
      return
    }

    this.time = new Time()
    this.sizes = new Sizes()
    this.setConfig()
    this.setStats()
    this.setScene()
    this.setCamera()
    this.setRenderer()
    this.setResources()

    this.currentScroll = 0
    this.previousScroll = 0

    if (document.readyState === 'complete') {
      this.setHorizontalScroll()
      this.setSections()
      this.setImageEffect()
      this.setTextAnimation()
      this.setHeader()
      this.setMenu()
    } else {
      window.addEventListener('DOMContentLoaded', () => {
        this.setHorizontalScroll()
        this.setSections()

        this.setImageEffect()
        this.setTextAnimation()
        this.setHeader()
        this.setMenu()
      })
    }

    this.sizes.on('resize', () => {
      this.resize()
    })

    this.update()
  }

  setConfig () {
    this.config = {}

    // Debug
    this.config.debug = window.location.hash === '#debug'

    // Pixel ratio
    this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

    // Width and height
    const boundings = this.targetElement.getBoundingClientRect()
    this.config.width = boundings.width
    this.config.height = boundings.height || window.innerHeight
  }

  setStats () {
    if (this.config.debug) {
      this.stats = new Stats(true)
    }
  }

  setScene () {
    this.scene = new THREE.Scene()
  }

  setCamera () {
    this.camera = new Camera()
  }

  setRenderer () {
    this.renderer = new Renderer({ rendererInstance: this.rendererInstance })

    this.targetElement.appendChild(this.renderer.instance.domElement)
  }

  setResources () {
    this.resources = new Resources(assets)
  }

  setHorizontalScroll () {
    this.horizontalScroll = new HorizontalScroll()
  }

  setSections () {
    this.imageScroll = new ImageScroll()
    this.cardFolding = new CardFolding()
    this.anchorLink = new AnchorLink()
  }

  setImageEffect () {
    this.imageDistortion = new ImageDistortion()
  }

  setTextAnimation () {
    this.text = new Text()
    this.textHover = new TextHover()
  }

  setHeader () {
    this.header = new Header()
  }

  setMenu () {
    this.menu = new Menu()
  }

  update () {
    if (this.sizes) { this.sizes.update() }
    if (this.imageDistortion) (this.imageDistortion.update(this.time.elapsed))
    if (this.stats) { this.stats.update() }

    if (this.camera) { this.camera.update() }

    if (this.renderer) { this.renderer.update() }

    if (this.horizontalScroll) { this.horizontalScroll.update(this.time.elapsed) }

    if (this.header) { this.header.update() }
    if (this.menu) { this.menu.update() }
  }

  resize () {
    // Config
    const boundings = this.targetElement.getBoundingClientRect()
    this.config.width = boundings.width
    this.config.height = boundings.height

    this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

    if (this.camera) { this.camera.resize() }

    if (this.renderer) { this.renderer.resize() }

    if (this.menu) { this.menu.resize() }
    if (this.imageDistortion) (this.imageDistortion.resize())
    if (this.horizontalScroll) { this.horizontalScroll.resize() }
    if (this.cardFolding) { this.cardFolding.resize() }
    if (this.anchorLink) { this.anchorLink.resize() }
  }

  destroy () {
    cancelAnimationFrame(this.raf)
    if (this.renderer) {
      this.renderer.destroy()
      this.renderer.instance.domElement.addEventListener('dblclick', null, false)
      this.targetElement.removeChild(this.renderer.instance.domElement)
      this.renderer = null
    }
    if (this.camera) {
      this.camera.destroy()
      this.camera = null
    }
    if (this.text) { this.text.destroy() }
    if (this.horizontalScroll) { this.horizontalScroll.destroy() }
    if (this.imageDistortion) { this.imageDistortion.destroy() }
    if (this.imageScroll) { this.imageScroll.destroy() }
    if (this.cardFolding) { this.cardFolding.destroy() }
    if (this.header) { this.header.destroy() }
    if (this.menu) { this.menu.destroy() }

    Experience.instance = null
  }
}
