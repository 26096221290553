import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import NormalizeWheel from 'normalize-wheel'

import Experience from './Experience.js'
import Scroll from '../scroll'
import SplitText from '../SplitText'

gsap.registerPlugin(ScrollTrigger)

export default class Menu {
  constructor () {
    this.init()
  }

  init () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.mediaQueryTablet = window.matchMedia('(max-width: 68.75rem)')
    this.experience = new Experience()
    this.config = this.experience.config
    this.scroll = new Scroll()
    this.timelines = []

    // スクロールの基準
    this.DOM = { main: document.querySelector('[data-menuncrollcontainer]') }
    this.wrappers = Array.from(this.DOM.main.querySelectorAll('[data-menuscroll]'))
    this.canvas = document.querySelector('.experience')
    this.wrapperWidth = this.wrappers[0].offsetWidth

    this.isActive = true
    if (this.mediaQueryTablet.matches) {
      this.current = -this.config.width * 1.8
      this.target = this.current
      this.ease = 0.05
    } else {
      this.current = 0
      this.target = this.current
      this.ease = 0.05
    }

    this.position = 0
    this.gap = 0
    this.scrollBase0 = 0
    this.scrollBase1 = +this.wrapperWidth
    this.scrollBase2 = -this.wrapperWidth

    // リンクの設定
    this.links = document.querySelectorAll('.js-global-menu__link')
    this.sectionHeights = this.experience.anchorLink.sectionHeights
    this.horizontalScrollLength = this.experience.anchorLink.horizontalScrollLength

    this.setOpenClose()
    this.setStyleBeforeTransition()
    this.setInfiniteScroll()
    this.setTransitions()
  }

  reculculate () {
    this.wrapperWidth = this.wrappers[0].offsetWidth
    this.scrollBase1 = +this.wrapperWidth
    this.scrollBase2 = -this.wrapperWidth
  }

  setOpenClose () {
    this.openButton = document.querySelector('.js-header__menuOpen')
    this.closeButton = document.querySelector('.js-global-menu-closeButton')

    this.openButton.addEventListener('click', () => this.menuOpenAnimation())
    this.closeButton.addEventListener('click', () => this.menuCloseAnimation())

    // コンテンツの格納
    this.itemContents1 = Array.from(this.wrappers[0].querySelectorAll('.js-menulist-item'))
    this.itemContents1Store = this.itemContents1.map(item => {
      const line = item.querySelector('.js-global-menu__line')
      const texts = Array.from(item.querySelectorAll('.js-global-menu__itemTitle>.line>p'))
      const splitText1 = new SplitText(texts[0], false, true, item)
      const splitText2 = new SplitText(texts[1], false, true, item)

      return {
        item,
        line,
        splitText1,
        splitText2
      }
    })
  }

  setStyleBeforeTransition () {
    this.itemContents1Store.forEach(item => {
      gsap.set(item.line, {
        y: '-100%'
      })
      item.splitText1.setAnimateVerticalTextEnglish(0.7)
      item.splitText2.setAnimateVerticalTextEnglish(0.7)
    })

    this.wrappers.forEach((wrapper, index) => {
      gsap.set(wrapper, {
        opacity: 0
      })
    })
  }

  menuOpenAnimation () {
    this.experience.imageDistortion.imageStore.forEach(image => {
      image.mesh.material.uniforms.uOpacity.value = 0
    })
    if (this.closeTl) {
      this.closeTl.kill()
    }
    const menu = document.querySelector('.js-global-menu')

    this.scroll.value.isActive = false

    this.openTl = gsap.timeline()

    this.openTl.to(menu, {
      duration: 0.5,
      opacity: 1,
      ease: 'power2.out',
      onStart: () => {
        menu.style.display = 'flex'
        menu.style.pointerEvents = 'auto'
        this.startTl = gsap.timeline()
        this.itemContents1Store.forEach((item, index) => {
          const delay = index * 0.15
          this.startTl.to(this.wrappers[0], {
            duration: 1,
            delay,
            opacity: 1,
            ease: 'none'
          }, 0)
            .to(item.line, {
              duration: 1.2,
              y: '0%',
              delay,
              ease: 'power2.out'
            }, 0)
            .to(item.splitText1, {
              delay,
              onStart: () => {
                item.splitText1.animateVerticalTextEnglish(0.6, 0.05, index)
              }
            }, 0.2)
            .to(item.splitText2, {
              delay,
              onStart: () => {
                item.splitText2.animateVerticalTextEnglish(0.6, 0.05, index)
              }
            }, 0.5)
        })
        this.startTl.to(this.wrappers[1], {
          duration: 1.5,
          opacity: 1
        }, 1.3)
          .to(this.wrappers[2], {
            duration: 1.5,
            opacity: 1
          }, 1.3)
      }
    }, 0)
      .to(this.experience.horizontalScroll.materialGlobalMenuBG.uniforms.uAlpha,
        {
          value: '1',
          duration: 0.5,
          ease: 'power2.out'
        }, 0)
    this.canvas.style.zIndex = '9999998'
    this.experience.horizontalScroll.plane.position.z = 11
    this.timelines.push(this.openTl)
    window.isMenuOpen = true
  }

  menuCloseAnimation () {
    if (this.openTl) {
      this.openTl.kill()
      this.openTl = null
    }
    if (this.startTl) {
      this.startTl.kill()
      this.startTl = null
    }
    this.itemContents1Store.forEach(item => {
      if (item.splitText1.verticalTl) {
        item.splitText1.verticalTl.kill()
        item.splitText1.verticalTl = null
      }
      if (item.splitText2.verticalTl) {
        item.splitText2.verticalTl.kill()
        item.splitText2.verticalTl = null
      }
    })
    this.setStyleBeforeTransition()
    const menu = document.querySelector('.js-global-menu')
    menu.style.pointerEvents = 'none'

    this.scroll.value.isActive = true
    this.experience.horizontalScroll.plane.position.z = 0

    this.closeTl = gsap.timeline()

    this.closeTl.to(menu, {
      duration: 0.5,
      opacity: 0,
      ease: 'power2.out',
      onStart: () => {
        gsap.to(this.experience.horizontalScroll.material.uniforms.uOpacity,
          {
            value: '0',
            duration: 0.3,
            ease: 'power2.out'
          })
      },
      onComplete: () => {
        menu.style.display = 'none'
        if (this.mediaQuery.matches) {
          this.current = -this.config.width * 1.8
          this.target = this.current
        } else {
          this.current = 0
          this.target = this.current
        }
      }
    }, 0)
      .to(this.experience.horizontalScroll.materialGlobalMenuBG.uniforms.uAlpha,
        {
          value: '0',
          duration: 1,
          ease: 'power2.out',
          onComplete: () => {
            this.experience.imageDistortion.imageStore.forEach(image => {
              gsap.to(image.mesh.material.uniforms.uOpacity, {
                value: 1,
                duration: 1.5,
                ease: 'power2.out'
              })
            })
            this.canvas.style.zIndex = '-1'
          }
        }, 0)
    window.isMenuOpen = false
    this.timelines.push(this.closeTl)
    this.timelines.push(this.startTl)
  }

  setInfiniteScroll () {
    window.addEventListener('wheel', this._onWheel.bind(this))
    // todo: スマホ対応

    window.addEventListener('touchstart', this._onTouchDown.bind(this))
    window.addEventListener('touchmove', this._onTouchMove.bind(this))
    window.addEventListener('touchend', this._onTouchUp.bind(this))
  }

  _onWheel (event) {
    if (window.isMenuOpen) {
      const normalizeWheel = NormalizeWheel(event)
      this.target += normalizeWheel.pixelY
    }
  }

  _onTouchDown (event) {
    if (window.isMenuOpen) {
      this.isDown = true
      this.position = this.current
      this.start = event.touches ? -event.touches[0].clientY : 0
    }
  }

  _onTouchMove (event) {
    if (window.isMenuOpen) {
      // const x = event.touches ? -event.touches[0].clientY : 0
      const x = event.touches ? -event.touches[0].clientY : 0
      this.distance = (this.start - x) * 2

      // if (this.distance > 40 || this.distance < -40) {
      //   // this.target = this.position - this.distance
      //   this.target -= this.distance
      // }
      // this.target -= this.distance
    }
  }

  _onTouchUp (event) {
    if (window.isMenuOpen) {
      this.isDown = false
      this.target -= this.distance
    }
  }

  setTransitions () {
    this.links.forEach((link, index) => {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        window.onAnchorScrolling = true
        this.scroll.value.ease = 0.04
        this.scroll.value.ease = 1
        gsap.to(this.experience.horizontalScroll.material.uniforms.uOpacity,
          {
            value: '0',
            duration: 0.5,
            ease: 'power2.out'
          })
        setTimeout(() => {
          this.menuCloseAnimation()
        }, 400)
        // ターゲットセクションに移動
        gsap.to(this.scroll.value, {
          duration: 1.2,
          ease: 'expo.out',
          target: () => {
            switch (index) {
              case 0:
              case 5:
              case 10:
                return 0
              case 1:
              case 6:
              case 11:
                return this.sectionHeights.home + this.sectionHeights.message
              case 2:
              case 7:
              case 12:
                return this.sectionHeights.home + this.sectionHeights.message + this.sectionHeights.projects + this.sectionHeights.archives
              case 3:
              case 8:
              case 13:
                return this.sectionHeights.home + this.sectionHeights.message + this.sectionHeights.projects + this.sectionHeights.archives + this.sectionHeights.inspirations
              case 4:
              case 9:
              case 14:
                return this.sectionHeights.home + this.sectionHeights.message + this.sectionHeights.projects + this.sectionHeights.archives + this.sectionHeights.inspirations + this.sectionHeights.principles + this.sectionHeights.lastquote + this.sectionHeights.lastmessage
            }
          },
          onUpdate: () => {
            if (!window.onAnchorScrolling) {
              window.onAnchorScrolling = true
            }
          },
          onComplete: () => {
            window.onAnchorScrolling = false
            this.mediaQueryTablet.matches ? this.scroll.value.ease = 0.2 : this.scroll.value.ease = 0.04
          }
        })
      })
    })
  }

  resize () {
    // メニューに関してはリサイズイベント不要
    this.reculculate()
  }

  update () {
    this.current = Math.round(gsap.utils.interpolate(this.current, this.target, this.ease))

    this.wrappers[0].style.transform = `translate3d(${this.scrollBase0 + this.current}px, 0,  0)`
    this.wrappers[1].style.transform = `translate3d(${this.scrollBase1 + this.current}px, 0,  0)`
    this.wrappers[2].style.transform = `translate3d(${this.scrollBase2 + this.current}px, 0,  0)`

    this.gap = this.target - this.current

    if (this.current > this.wrapperWidth) {
      this.current = 0
      this.target = this.gap
    }

    if (this.current < -this.wrapperWidth) {
      this.current = 0
      this.target = this.gap
    }

    this.speed = Math.min(Math.abs(gsap.utils.clamp(0, this.wrapperWidth, this.target) - gsap.utils.interpolate(this.current, this.target, this.ease)), 1000) / 1000
  }

  destroy () {
    this.openButton.removeEventListener('click', () => this.menuOpenAnimation())
    this.closeButton.removeEventListener('click', () => this.menuCloseAnimation())
  }
}
