import * as THREE from 'three'
import Experience from './Experience.js'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera {
  constructor (_options) {
    // Options
    this.experience = new Experience()
    this.config = this.experience.config
    this.debug = this.experience.debug
    this.time = this.experience.time
    this.sizes = this.experience.sizes
    this.targetElement = this.experience.targetElement
    this.scene = this.experience.scene

    // Set up
    this.mode = 'debug' // defaultCamera \ debugCamera

    this.setInstance()
    this.setModes()
  }

  setInstance () {
    // Set up
    this.instance = new THREE.PerspectiveCamera(20, this.config.width / this.config.height, 100, 2000)
    this.instance.rotation.reorder('YXZ')

    this.scene.add(this.instance)
  }

  setModes () {
    this.modes = {}

    // Default
    this.modes.default = {}
    this.modes.default.instance = this.instance.clone()
    this.modes.default.instance.rotation.reorder('YXZ')

    // Debug
    this.modes.debug = {}
    this.modes.debug.instance = this.instance.clone()
    this.modes.debug.instance.rotation.reorder('YXZ')
    this.modes.debug.instance.position.set(0, 0, 0)
    this.modes.debug.instance.position.z = 600

    // fit canvas to window
    this.instance.fov = 2 * Math.atan((this.config.height / 2) / 600) * (180 / Math.PI)
    this.instance.updateProjectionMatrix()
  }

  resize () {
    this.instance.fov = 2 * Math.atan((this.config.height / 2) / 600) * (180 / Math.PI)
    this.instance.aspect = this.config.width / this.config.height
    this.instance.updateProjectionMatrix()

    this.modes.default.instance.fov = 2 * Math.atan((this.config.height / 2) / 600) * (180 / Math.PI)
    this.modes.default.instance.aspect = this.config.width / this.config.height
    this.modes.default.instance.updateProjectionMatrix()

    this.modes.debug.instance.fov = 2 * Math.atan((this.config.height / 2) / 600) * (180 / Math.PI)
    this.modes.debug.instance.aspect = this.config.width / this.config.height
    this.modes.debug.instance.updateProjectionMatrix()
  }

  update () {
    // Update debug orbit controls
    // this.modes.debug.orbitControls.update()

    // Apply coordinates
    this.instance.position.copy(this.modes[this.mode].instance.position)
    this.instance.quaternion.copy(this.modes[this.mode].instance.quaternion)
    this.instance.updateMatrixWorld() // To be used in projection
  }

  destroy () {
  }
}
