'use strict';
export default class Pointer {
    constructor() {
        if (Pointer.instance)
            return Pointer.instance;
        this.x = 0;
        this.y = 0;
        Pointer.instance = this;
        this.init();
    }
    static getInstance() {
        if (!Pointer.instance) {
            Pointer.instance = new Pointer();
        }
        return Pointer.instance;
    }
    init() {
        document.addEventListener('pointermove', this.onPointerMove.bind(this));
    }
    onPointerMove(e) {
        this.x = e.clientX;
        this.y = e.clientY;
    }
}
