import gsap from 'gsap'
import SplitText from './SplitText'
import Scroll from './scroll'

window.openingProgress = 1
export default class Preloader {
  constructor () {
    this.mediaQuery = window.matchMedia('(max-width: 34.375rem)')
    this.scroll = new Scroll()
    this.preloader = document.querySelector('.js-preloader')
    this.preloaderContents = Array.from(document.querySelectorAll('.js-preloader__content'))
    this.preloaderNumberBlock = document.querySelector('.js-preloader__progress-text ')
    this.preloaderNumber = document.querySelector('.js-preloader__progress-number')
    this.preloaderInner = document.querySelector('.js-preloader__progress-inner')

    this.heroTitleTop = document.querySelector('.js-hero__title-1>.line>p')
    this.heroTitleBottom = document.querySelector('.js-hero__title-2>.line>p')
    this.heroNameTop = document.querySelector('.js-hero__nameTop>.line>p')
    this.heroNameBottom = document.querySelector('.js-hero__nameBottom>.line>p')
    this.heroAge = document.querySelector('.js-hero__age>.line>p')
    this.headerLogo = document.querySelector('.js-header__logo>p')
    this.headerNavs = Array.from(document.querySelectorAll('.js-page-nav__item>.line'))
    this.headerMainNav = document.querySelector('.js-header__main-nav')

    window.addEventListener('DOMContentLoaded', () => {
      this.setPreloaderContents()
      this.fadeOutPreloader()
      this.contentFadeIn()
    })
    window.addEventListener('load', () => {
    })
  }

  setPreloaderContents () {
    // スクロール禁止
    this.scroll.disable()

    this.preloaderMessagesStore = this.preloaderContents.map((content) => {
      const splitTexts = []
      const texts = Array.from(content.querySelectorAll('p'))
      texts.forEach((text, index) => {
        const splitText = new SplitText(text, false, content)
        splitTexts.push(splitText)
      })
      return {
        splitTexts
      }
    })
    this.preloaderMessagesStore.forEach((store, index) => {
      store.splitTexts.forEach((splitText) => {
        splitText.setAnimateVerticalText(0.5)
      })
    })
    this.preloadTl = gsap.timeline()
    const messageDuration = 2.4
    const messageOutDuration = 2.0
    this.totalPreloaderDuration = messageDuration + messageOutDuration

    this.numbersSetting = { progress: 0 }

    this.numbers = {
      0: '零',
      1: '一',
      2: '二',
      3: '三',
      4: '四',
      5: '五',
      6: '六',
      7: '七',
      8: '八',
      9: '九',
      10: '十',
      11: '十一',
      12: '十二',
      13: '十三',
      14: '十四',
      15: '十五',
      16: '十六',
      17: '十七',
      18: '十八',
      19: '十九',
      20: '二十',
      21: '二一',
      22: '二二',
      23: '二三',
      24: '二四',
      25: '二五',
      26: '二六',
      27: '二七',
      28: '二八',
      29: '二九',
      30: '三十',
      31: '三一',
      32: '三二',
      33: '三三',
      34: '三四',
      35: '三五',
      36: '三六',
      37: '三七',
      38: '三八',
      39: '三九',
      40: '四十',
      41: '四一',
      42: '四二',
      43: '四三',
      44: '四四',
      45: '四五',
      46: '四六',
      47: '四七',
      48: '四八',
      49: '四九',
      50: '五十',
      51: '五一',
      52: '五二',
      53: '五三',
      54: '五四',
      55: '五五',
      56: '五六',
      57: '五七',
      58: '五八',
      59: '五九',
      60: '六十',
      61: '六一',
      62: '六二',
      63: '六三',
      64: '六四',
      65: '六五',
      66: '六六',
      67: '六七',
      68: '六八',
      69: '六九',
      70: '七十',
      71: '七一',
      72: '七二',
      73: '七三',
      74: '七四',
      75: '七五',
      76: '七六',
      77: '七七',
      78: '七八',
      79: '七九',
      80: '八十',
      81: '八一',
      82: '八二',
      83: '八三',
      84: '八四',
      85: '八五',
      86: '八六',
      87: '八七',
      88: '八八',
      89: '八九',
      90: '九十',
      91: '九一',
      92: '九二',
      93: '九三',
      94: '九四',
      95: '九五',
      96: '九六',
      97: '九七',
      98: '九八',
      99: '九九',
      100: '百'
    }

    gsap.to(this.numbersSetting, {
      progress: 100,
      duration: this.totalPreloaderDuration,
      ease: 'power3.inOut',
      onUpdate: () => {
        this.preloaderNumber.textContent = this.numbers[Math.trunc(this.numbersSetting.progress)]
      }
    })
    gsap.from(this.preloaderInner, {
      x: '-100%',
      duration: this.totalPreloaderDuration,
      ease: 'power3.inOut'
    })
    gsap.to(this.preloaderInner, {
      opacity: 1,
      duration: 2,
      ease: 'none'
    })
    gsap.to(this.preloaderInner, {
      opacity: 0,
      delay: this.totalPreloaderDuration - 1,
      duration: 1.5,
      ease: 'expo.in'
    })

    // テキストのランダム表示
    if (!this.mediaQuery.matches) {
      const randomNumber = Math.random()
      if (randomNumber < 0.25 && randomNumber > 0) {
        this.preloadTl.to(this.preloaderContents[0], {
          duration: messageDuration,
          opacity: 1,
          ease: 'power3.out',
          onStart: () => {
            this.preloaderMessagesStore[0].splitTexts.forEach((splitText, index) => {
              splitText.animateVerticalText(0.05, index)
            }
            )
          }
        })
          .to(this.preloaderContents[0], {
            duration: messageOutDuration,
            opacity: 0.1,
            ease: 'power3.in'
          })
      } else if (randomNumber < 0.5 && randomNumber >= 0.25) {
        this.preloadTl.to(this.preloaderContents[1], {
          duration: messageDuration,
          opacity: 1,
          ease: 'power3.out',
          onStart: () => {
            this.preloaderMessagesStore[1].splitTexts.forEach((splitText, index) => {
              splitText.animateVerticalText(0.05, index)
            }
            )
          }
        })
          .to(this.preloaderContents[1], {
            duration: messageOutDuration,
            opacity: 0.1,
            ease: 'power3.in'
          })
      } else if (randomNumber < 0.75 && randomNumber >= 0.5) {
        this.preloadTl.to(this.preloaderContents[2], {
          duration: messageDuration,
          opacity: 1,
          ease: 'power3.out',
          onStart: () => {
            this.preloaderMessagesStore[2].splitTexts.forEach((splitText, index) => {
              splitText.animateVerticalText(0.05, index)
            }
            )
          }
        })
          .to(this.preloaderContents[2], {
            duration: messageOutDuration,
            opacity: 0.1,
            ease: 'power3.in'
          })
      } else if (randomNumber <= 1 && randomNumber >= 0.75) {
        this.preloadTl.to(this.preloaderContents[3], {
          duration: messageDuration,
          opacity: 1,
          ease: 'power3.out',
          onStart: () => {
            this.preloaderMessagesStore[3].splitTexts.forEach((splitText, index) => {
              splitText.animateVerticalText(0.05, index)
            }
            )
          }
        })
          .to(this.preloaderContents[3], {
            duration: messageOutDuration,
            opacity: 0.1,
            ease: 'power3.in'
          })
      }
    }
    this.preloadTl.to(this.preloaderNumberBlock, {
      opacity: 1,
      duration: messageDuration,
      ease: 'power3.out'
    }, 0)
    this.preloadTl.to(this.preloaderNumberBlock, {
      opacity: 0,
      duration: messageOutDuration,
      ease: 'expo.in'
    }, messageDuration * 1.15)
  }

  fadeOutPreloader () {
    this.preloadTl.to(this.preloader, {
      duration: 2,
      opacity: 0,
      ease: 'expo.inOut',
      onComplete: () => {
        this.preloader.style.pointerEvents = 'none'
        this.scroll.enable()
      }
    })
  }

  contentFadeIn () {
    this.durationRatio = 0.8
    this.contentFadeInDelay = this.totalPreloaderDuration * 1.2
    const contentTl = gsap.timeline()
    contentTl.from(this.heroTitleTop, {
      duration: 2 * this.durationRatio,
      delay: 0.5 * this.durationRatio,
      opacity: 0,
      y: '100%',
      ease: 'power3.out'
    }, this.contentFadeInDelay)
      .from(this.heroTitleBottom, {
        duration: 2 * this.durationRatio,
        opacity: 0,
        delay: 0.7 * this.durationRatio,
        y: '100%',
        ease: 'power3.out'
      }, this.contentFadeInDelay)
      .from(this.heroNameBottom, {
        duration: 1.8 * this.durationRatio,
        opacity: 0,
        delay: 0.9 * this.durationRatio,
        y: '100%',
        ease: 'power3.out'
      }, this.contentFadeInDelay)
      .from(this.heroNameTop, {
        duration: 1.8 * this.durationRatio,
        opacity: 0,
        delay: 1.1 * this.durationRatio,
        y: '100%',
        ease: 'power3.out'
      }, this.contentFadeInDelay)
      .from(this.heroAge, {
        duration: 2.2 * this.durationRatio,
        opacity: 0,
        delay: 0.7 * this.durationRatio,
        y: '100%',
        ease: 'power3.out'
      }, this.contentFadeInDelay)

    // ヘッダーのフェードイン
    contentTl.to(window, {
      openingProgress: 0,
      duration: 5,
      ease: 'power1.inOut'
    }, this.contentFadeInDelay - 1.7)
    contentTl.from(this.headerLogo, {
      duration: 1.5 * this.durationRatio,
      opacity: 0,
      delay: 1.8 * this.durationRatio,
      y: '100%',
      ease: 'power3.out'
    }, this.contentFadeInDelay)
      .from(this.headerNavs[0], {
        duration: 1 * this.durationRatio,
        opacity: 0,
        delay: 2.0 * this.durationRatio,
        y: '200%',
        ease: 'power2.out'
      }, this.contentFadeInDelay)
      .from(this.headerNavs[1], {
        duration: 1 * this.durationRatio,
        opacity: 0,
        delay: 2.1 * this.durationRatio,
        y: '200%',
        ease: 'power2.out'
      }, this.contentFadeInDelay)
      .from(this.headerNavs[2], {
        duration: 1 * this.durationRatio,
        opacity: 0,
        delay: 2.2 * this.durationRatio,
        y: '200%',
        ease: 'power2.out'
      }, this.contentFadeInDelay)
      .from(this.headerNavs[3], {
        duration: 1 * this.durationRatio,
        opacity: 0,
        delay: 2.3 * this.durationRatio,
        y: '200%',
        ease: 'power2.out'
      }, this.contentFadeInDelay)
      .from(this.headerNavs[4], {
        duration: 1 * this.durationRatio,
        opacity: 0,
        delay: 2.4 * this.durationRatio,
        y: '200%',
        ease: 'power2.out'
      }, this.contentFadeInDelay)
      .from(this.headerMainNav, {
        duration: 1 * this.durationRatio,
        opacity: 0,
        delay: 2.6 * this.durationRatio,
        y: '200%',
        ease: 'power2.out'
      }, this.contentFadeInDelay)
  }
}
