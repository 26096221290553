import gsap from 'gsap'

export default class ClipBoard {
  constructor (event) {
    this.init(event)
    this.setEventListener()
  }

  init (event) {
    this.isSet = false
    this.target = document.querySelector('.js-footer__email__copy')
    this.targetParagraph = document.querySelector('.js-footer__email__copy>p')
    this.duplicateElement = this.targetParagraph.cloneNode(true)
    this.duplicateElement.style.position = 'absolute'
    this.duplicateElement.style.top = '100%'
    this.duplicateElement.style.left = '0'
    this.duplicateElement.style.transform = 'translate3d(0, 0, 0)'
    this.arrow = document.querySelector('.js-email__arrow')

    this.duplicateElement.style.opacity = '1'
    this.duplicateElement.style.webkitBackfaceVisibility = 'hidden'
    this.duplicateElement.textContent = 'Mail is copied!'
    this.targetParagraph.parentElement.appendChild(this.duplicateElement)

    this.tl = gsap.timeline({
      paused: false,
      onReverseComplete: () => {
        this.tl.pause(0)
      }
    })
  }

  setEventListener () {
    this.target.addEventListener('click', (e) => {
      console.log('hit')
      e.preventDefault()
      if (!this.tl.isActive() && !this.isSet) {
        this.copyToClipboard()
      } else if (!this.tl.isActive() && this.isSet) {
        this.tl.play()
        setTimeout(() => {
          this.tl.reverse()
        }, 2000)
      } else {
        return false
      }
    })
  }

  async copyToClipboard (event) {
    try {
      await navigator.clipboard.writeText('utsunomiya@shhh.jp')
      this.isSet = true
      this.targetParagraphs = Array.from(document.querySelectorAll('.js-footer__email__copy>p'))
      this.targetParagraphs.forEach((text, index) => {
        if (index === 0 || index === 1) {
          this.tl.to(text, { duration: 0.5, opacity: 0, ease: 'expo.out' }, 0)
        } else {
          this.tl.to(this.duplicateElement, {
            duration: 0.5,
            opacity: 1,
            y: '-100%',
            ease: 'expo.out'
          }, 0.2)
            .to(this.arrow, {
              duration: 0.5,
              opacity: 0,
              ease: 'expo.out'
            }, 0)
        }
      })

      setTimeout(() => {
        this.tl.reverse()
      }, 2000)
    } catch (error) {
      console.error(error)
    }
  }

  destroy () {
    this.target.removeEventListener('click', this.copyToClipboard)
  }
}
